import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";

import { PAGINATION_COUNT } from "../../config/config";

import { MESSAGES, TOOLTIP } from "../../constants/messages";
import { APP_PAGES } from "../../constants/app-pages";

import PmivrSnackbar from "../../components/common/dialog/pmivr-snackbar";
import PmivrLabel from "../../components/common/label/pmivr-label";
import { PmivrDialog } from "../../components/common/dialog/pmivr-dialog";
import PmivrDateTimeRangePicker from "../../components/common/date-time-range/pmivr-date-time-range";
import LogInfo from "./components/log-info";

import AppUtil from "../../util/app.util";

import AuditService from "../../services/audit.service";
import ConfigService from "../../services/config.service";

/**
 * Lists all the AGI call logs from db
 * @returns {React.Component} Html element to render
 */
const Logs = () => {
    // using the open method from the snackbar component
    const snackbarRef = useRef();
    // Ref of the date time filter component, used to reset the date time
    const dateTimeRef = useRef();
    // call logs list, total number of calls made, and total number of successful payments
    const [callLogs, setCallLogs] = useState({ callLogsList: [], totalCalls: 0, totalSuccessfulPayments: 0 });
    // deployment environments configured
    const [deploymentEnvironment, setDeploymentEnvironment] = useState({ environments: [] });
    /**
     * Properties for show log details dialog:
     * showLogInfoDialog: show or hide log details dialog
     * currentLog: log object which is passed to the details dialog for display
     */
    const [logDetailsDialogProps, setLogDetailsDialogProps] = useState({ showLogInfoDialog: false, currentLog: {} });
    // filter object for filtering the call logs
    const [filterCallLogs, setFilterCallLogs] = useState(
        { text: '', stepName: '', dnid: '', callerIdNum: '', sessionId: '', environment: '', startDate: '', endDate: '' }
    );
    // props for the pagination of the call logs list
    const [pagination, setPagination] = useState({
        totalPages: 0,    // total pages in pagination of call logs
        currentPage: 0, // current page displayed in pagination of call logs
        totalResults: 0,  // total number of call logs
        count: PAGINATION_COUNT  // count for number of call logs to be displayed at a time on screen
    });

    useEffect(() => {
        // loading initial logs list
        const loadInitialCallLogs = async () => {
            await loadCallLogs();
            await loadEnvironments();
        };
        loadInitialCallLogs();
    }, []);

    // loading the deployment environments configured
    const loadEnvironments = async () => {
        const configuredEnv = await ConfigService.getDeploymentEnvironments();
        if (configuredEnv?.length) {
            let options = [{ text: 'Select Environment', value: '', disabled: true }];
            configuredEnv.map((env) => {
                if (env?.key) {
                    options.push({ text: env?.name, value: env?.key, disabled: false });
                }
            });
            setDeploymentEnvironment({ ...deploymentEnvironment, environments: options });
        }
    }

    /**
     * Updating the pagination props in the state
     * @param {Number} dataCount total number of  call logs
     * @param {Number} pageNo page number of the call logs list
     */
    const setPaginationProps = (dataCount = 0, pageNo = 1) => {
        setPagination((prevPaginationObj) => {
            const newPaginationObj = { ...prevPaginationObj };
            newPaginationObj.totalPages = Math.ceil(dataCount / newPaginationObj.count);
            newPaginationObj.currentPage = pageNo;
            newPaginationObj.totalResults = dataCount;
            return newPaginationObj;
        });
    }

    /**
     * Getting the call logs from the DB as per count, page no, etc
     * @param {Integer} pageNo page of call logs list to be displayed, which will be always incremented by default when this function is called
     * pageNo is incremented every time by 1, because we need to load more logs when this function is called, 
     * in case of any filter, we will reset otherwise just increment pageNo by 1
     * @param {string} action whether to reset or next, if the action is next, just append new 10 records to list, otherwise reset
     * @param {Object} filters this is the updated filters, if this function needs latest filters to fetch call logs immediately
     */
    const loadCallLogs = async (pageNo = pagination.currentPage + 1, action = 'next', filters = null) => {
        try {
            const filter = filters || {
                searchText: filterCallLogs.text,
                stepName: filterCallLogs.stepName,
                dnid: filterCallLogs.dnid,
                callerIdNum: filterCallLogs.callerIdNum,
                sessionId: filterCallLogs.sessionId,
                environment: filterCallLogs.environment,
                startDate: filterCallLogs.startDate,
                endDate: filterCallLogs.endDate,
                count: pagination.count,
                pageNo
            };
            const response = await AuditService.getIvrCallLogs(filter);

            setCallLogs((prevCallLogs) => {
                let newCallLogs = [...prevCallLogs.callLogsList];
                // move to page 1, and reset pagination if any of the filter is added
                if (pageNo === 1 || action === 'reset') {
                    newCallLogs = response?.data.logs;
                } else if (action === 'next') {
                    // Append 10 more logs to the existing list
                    newCallLogs = [...newCallLogs, ...response?.data.logs];
                }

                return {
                    ...prevCallLogs,
                    callLogsList: newCallLogs,
                    totalCalls: response?.data.totalCalls,
                    totalSuccessfulPayments: response?.data.totalSuccessfulPayments
                };
            });

            setPaginationProps(response?.dataCount, pageNo);
        } catch (err) {
            // opening the snackbar
            if (snackbarRef?.current) {
                snackbarRef.current.open(MESSAGES.SOMETHING_WENT_WRONG);
            }
        }
    }

    /**
     * Resetting the list of call logs from state. 
     * If not found, then reload call logs from DB
     */
    const resetData = async () => {
        // also reset the date-time filter
        if (dateTimeRef?.current) {
            dateTimeRef.current.resetDateTime();
        }
        // Clear the cached data and make a fresh API call
        try {
            const response = await AuditService.getIvrCallLogs({
                searchText: '',
                stepName: '',
                dnid: '',
                callerIdNum: '',
                sessionId: '',
                environment: '',
                startDate: '',
                endDate: '',
                count: pagination.count,
                pageNo: 1
            });
            setCallLogs({
                ...callLogs, callLogsList: response?.data.logs, totalCalls: response?.data.totalCalls,
                totalSuccessfulPayments: response?.data.totalSuccessfulPayments
            });
            setPaginationProps(response?.dataCount, 1);
            // reset filter option
            setFilterCallLogs({ text: '', stepName: '', dnid: '', callerIdNum: '', sessionId: '', environment: '', startDate: '', endDate: '' });
        } catch (err) {
            // opening the snackbar
            if (snackbarRef?.current) {
                snackbarRef.current.open(MESSAGES.SOMETHING_WENT_WRONG);
            }
        }
    }

    /**
     * Updates filter state for call logs
     * @param {string} field - field to update in filter state
     * @param {string} value - new value of the filter field
     */
    const handleInputChange = (field, value) => {
        setFilterCallLogs((prev) => {
            const newFilterCallLogs = { ...prev, [field]: value?.toLowerCase() };
            // Check if all fields are empty after input change, and reset
            const allFieldsEmpty = Object.values(newFilterCallLogs).every(val => val === '');
            if (allFieldsEmpty) {
                resetData();
            }
            return newFilterCallLogs;
        });
    }

    /**
     * Handles the scroll event to load more call logs if the user has scrolled to the bottom.
     * @param {Event} event - The scroll event.
     */
    const handleScroll = (event) => {
        const { scrollTop, clientHeight, scrollHeight } = event.target;
        const threshold = 5;
        // Check if the user has scrolled near the bottom.
        const bottom = scrollHeight - scrollTop - clientHeight <= threshold;
        // if user has scrolled to bottom and there are still some records left to fetch, then load call logs with updated pagination
        if (bottom && pagination.currentPage < pagination.totalPages) {
            // increment the current page with 1
            loadCallLogs(pagination.currentPage + 1, 'next');
        }
    }

    /**
     * Update filters or load call logs (only if isSubmit is true) according to the selected date range
     * @param {{ startDate: string, endDate: string }} dateTimeRange
     * @param {boolean} isSubmit - if true, then it means load logs according to date time range, otherwise just update the filters
     */
    const handleDateTimeRangeChange = (dateTimeRange, isSubmit = false) => {
        const updatedFilters = {
            ...filterCallLogs,
            startDate: dateTimeRange.startDate,
            endDate: dateTimeRange.endDate
        };
        setFilterCallLogs(updatedFilters);
        // if enter key has been pressed i.e. submitted the date range
        if (isSubmit) {
            const pageNo = 1;
            loadCallLogs(pageNo, 'reset', updatedFilters);
        }
    }

    return (
        <>
            <PmivrSnackbar ref={snackbarRef} />

            {/* dialog box to show more details of the log */}
            <PmivrDialog showDialog={logDetailsDialogProps.showLogInfoDialog} cssClass='modal-width-medium' title={"Log info"}
                closeDialog={() => setLogDetailsDialogProps({ showLogInfoDialog: false, currentLog: {} })}
                message={<LogInfo logInfo={logDetailsDialogProps.currentLog} />}
                footer={<></>} />

            <div className="pmivr-filter-headers">
                <div className="row pt-1">
                    <div className="row border-bottom pb-3 pt-3">
                        <div className="col-lg-6">
                            <div className="px-3 pmivr-breadcrumb-list">
                                <Link to={APP_PAGES.HOME}>Home</Link> / Call Logs
                            </div>
                        </div>
                    </div>
                </div>
                {/* header for search and actions */}
                <div className="row p-2 pt-2">
                    <div className="col-sm-2 pmivr-relative">
                        <PmivrLabel label="Search In Call Logs" tooltip={TOOLTIP.INFO.CALL_LOGS.SEARCH_TEXT} cssClass="my-0" />
                        <input type="text" id="searchBox" className="form-control pmivr-input" value={filterCallLogs.text}
                            placeholder="Search text" onChange={(e) => handleInputChange('text', e.target.value)}
                            onKeyDown={(e) => AppUtil.isEnterKey(e) ? loadCallLogs(1, 'reset') : null} />
                    </div>
                    <div className="col-sm-2 pmivr-relative">
                        <PmivrLabel label="Step Name" tooltip={TOOLTIP.INFO.CALL_LOGS.STEP_NAME} cssClass="my-0" />
                        <input type="text" id="stepNameBox" className="form-control pmivr-input" value={filterCallLogs.stepName}
                            placeholder="Step Name" onChange={(e) => handleInputChange('stepName', e.target.value)}
                            onKeyDown={(e) => AppUtil.isEnterKey(e) ? loadCallLogs(1, 'reset') : null} />
                    </div>
                    <div className="col-sm-1 pmivr-relative">
                        <PmivrLabel label="DNID" tooltip={TOOLTIP.INFO.CALL_LOGS.DNID} cssClass="my-0" />
                        <input type="text" id="dnidBox" className="form-control pmivr-input" value={filterCallLogs.dnid}
                            placeholder="DNID" onChange={(e) => handleInputChange('dnid', e.target.value)}
                            onKeyDown={(e) => AppUtil.isEnterKey(e) ? loadCallLogs(1, 'reset') : null} />
                    </div>
                    <div className="col-sm-2 pmivr-relative">
                        <PmivrLabel label="Caller ID Number" tooltip={TOOLTIP.INFO.CALL_LOGS.CALLER_ID_NUMBER} cssClass="my-0" />
                        <input type="text" id="callerIdNumBox" className="form-control pmivr-input" value={filterCallLogs.callerIdNum}
                            placeholder="Caller ID Number" onChange={(e) => handleInputChange('callerIdNum', e.target.value)}
                            onKeyDown={(e) => AppUtil.isEnterKey(e) ? loadCallLogs(1, 'reset') : null} />
                    </div>
                    <div className={`col-md-${deploymentEnvironment?.environments?.length !== 0 ? '1' : '2'} pmivr-relative`}>
                        <PmivrLabel label="Session ID" tooltip={TOOLTIP.INFO.CALL_LOGS.SESSION_ID} cssClass="my-0" />
                        <input type="text" id="sessionIdBox" className="form-control pmivr-input" value={filterCallLogs.sessionId}
                            placeholder="Session ID" onChange={(e) => handleInputChange('sessionId', e.target.value)}
                            onKeyDown={(e) => AppUtil.isEnterKey(e) ? loadCallLogs(1, 'reset') : null} />
                    </div>
                    {deploymentEnvironment?.environments?.length !== 0 ? (
                        <div className="col-md-2 pmivr-relative">
                            <PmivrLabel label="Environment" tooltip={TOOLTIP.INFO.CALL_LOGS.ENVIRONMENT} cssClass="my-0" />
                            <select className="pmivr-select" value={filterCallLogs.environment}
                                onChange={(e) => handleInputChange('environment', e.target.value)}>
                                {
                                    deploymentEnvironment?.environments?.map((env) => {
                                        return (
                                            <option key={env.value} value={env.value} disabled={env.disabled}>
                                                {env.text}
                                            </option>
                                        );
                                    })
                                }
                            </select>
                        </div>
                    ) : <></>}
                    <div className="col-md-2 d-flex align-items-end">
                        <button title="Reset call logs" type="button" onClick={() => resetData()} className="float-start pmivr-btn-secondary pmivr-reset-link me-1">
                            Reset
                        </button>
                        <button title="Search call logs" type="button" onClick={() => loadCallLogs(1, 'reset')} className="float-start pmivr-btn-app pmivr-reset-link">
                            Search
                        </button>
                    </div>
                </div>

                {/* Filter according to date and time range */}
                <PmivrDateTimeRangePicker onChange={handleDateTimeRangeChange} ref={dateTimeRef} />

                {/* total call logs and agi calls bar */}
                <div className="row pe-1">
                    <div className="col-lg-6 d-flex">
                        <div className="px-3 pt-3 pmivr-breadcrumb-list">Total AGI Calls: {callLogs.totalCalls || 0}</div>
                        <div className="px-3 pt-3 pmivr-breadcrumb-list">
                            Total Successful Payments: {callLogs.totalSuccessfulPayments || 0}
                        </div>
                        <div className="px-3 pt-3 pmivr-breadcrumb-list">Total Records: {pagination.totalResults || 0}</div>
                    </div>
                </div>

                {/* list of call logs table */}
                <div className="row me-2 ms-2">
                    <table className="table table-body-block pmivr-table header-fixed border mt-2">
                        <thead>
                            <tr>
                                <th width="10%" className="text-center">Timestamp</th>
                                <th width="5%" className="text-center">DNID</th>
                                <th width="5%" className="text-center">Lext</th>
                                <th width="10%" className="text-center">Session ID</th>
                                <th width="10%" className="text-center">Caller Id Number</th>
                                <th width="6%" className="text-center">Env</th>
                                <th width="7%" className="text-center">Business Code</th>
                                <th width="10%" className="text-center">Step Name</th>
                            </tr>
                        </thead>
                        <tbody className="pmivr-scroll" onScroll={handleScroll}>
                            {(callLogs?.callLogsList.length) ?
                                callLogs.callLogsList.map((callLog, index) => {
                                    return (
                                        <tr key={index} onClick={() => setLogDetailsDialogProps({ showLogInfoDialog: true, currentLog: callLog })}
                                            className="cursor-pointer">
                                            <td width="10%" title={callLog.startTime} className="text-center pt-2">{AppUtil.formatDateInLocal(callLog.startTime)}</td>
                                            <td width="5%" title={callLog.dnid} className="text-center pt-2">{callLog.dnid}</td>
                                            <td width="5%" title={callLog.lext} className="text-center pt-2">{callLog.lext || '-'}</td>
                                            <td width="10%" title={callLog.sessionId} className="text-center pt-2">{callLog.sessionId}</td>
                                            <td width="10%" title={callLog.callerIdNum} className="text-center pt-2">{callLog.callerIdNum}</td>
                                            <td width="6%" title={callLog.environment} className="text-center pt-2">{callLog.environment || '-'}</td>
                                            <td width="7%" title={callLog.businessCode} className="text-center pt-2">{callLog.businessCode}</td>
                                            <td width="10%" title={callLog.stepName} className="text-center pt-2">{callLog.stepName || '-'}</td>
                                        </tr>
                                    )
                                }) : <tr><td>No Call Logs</td></tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

export default Logs;