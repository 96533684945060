import { useContext } from 'react';

import PmivrOverlayTrigger from '../../common/overlay-trigger/pmivr-overlay-trigger.js';
import PmivrLabel from '../../common/label/pmivr-label.js';

import { VOICE_FILE_UPLOAD_TYPE } from "../../../constants/voice-file";
import { TOOLTIP } from "../../../constants/messages";
import { CSS_CLASSES } from '../../../constants/css-classes.js';
import { SingleVoiceContext } from '../../../contexts/app-context';
import { envConfig } from '../../../environment/index.js';

import TextToSpeechOption from "../tts-option/TextToSpeechOption";
import UploadVoiceFileOption from "../upload-voice-file-option/UploadVoiceFileOption";
import AudioPlayer from '../../common/audio-player/audio-player.js';
import VoiceFilesSelect from '../../common/voice-files-select/voice-files-select';

import VoiceFileOptionService from '../../../services/voice-file-option.service';

/**
 * The voice file setting option for option user control
 * @param {Object} props props data from parent component
 * @returns {React.Component} Html element to render
 */
const VoiceFileSettingModal = () => {

    const { rightPanelEventHandler, element, supportedLanguages, selectedLanguage, updateVoiceFileStateInfo,
        voiceFileUploadMethod, setVoiceFileUploadMethod, voiceFilePath,
        updateVoiceFilePathState, voiceFileDetails, resetStates, uploadRef, ttsRef } = useContext(SingleVoiceContext);

    return (
        <>
            <div>
                <ul className="nav nav-tabs text-center" role="tablist">
                    {
                        supportedLanguages.map((language) => {
                            return (
                                <li className="nav-item  px-2" role="presentation">
                                    <button className={selectedLanguage === language ? "nav-link active " : "nav-link"} data-bs-target="#language-options" type="button" role="tab" title={language} aria-controls="language-options"
                                        onClick={(event) => { updateVoiceFileStateInfo(language); }}>{language}</button>
                                </li>
                            );
                        })
                    }
                </ul>
            </div>
            <div className="mt-3" id="language-options">
                <ul className="nav nav-pills pmivr-rounded-circle-tabs text-center d-flex" id="pills-tab" role="tablist">
                    <li className="nav-item flex-grow-1" role="presentation">
                        <button
                            className={voiceFileUploadMethod === VOICE_FILE_UPLOAD_TYPE.LIBRARY ? "nav-link active d-inline w-100" : "nav-link d-inline w-100"}
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Add file path"
                            id="filePath-tab"
                            data-bs-target="#file-path"
                            type="button"
                            role="tab"
                            aria-controls="file-path"
                            aria-selected={voiceFileUploadMethod === VOICE_FILE_UPLOAD_TYPE.LIBRARY}
                            onClick={(event) => { setVoiceFileUploadMethod(VoiceFileOptionService.getVoiceFileUploadMethod(event.currentTarget.id)); }}
                        >
                            <i className="bi bi-file-earmark-music"></i>
                            <p>Voice File</p>
                        </button>
                    </li>
                    <li className="nav-item flex-grow-1" role="presentation">
                        <button
                            className={voiceFileUploadMethod === VOICE_FILE_UPLOAD_TYPE.TTS ? "nav-link active d-inline w-100" : "nav-link d-inline w-100"}
                            id="tts-file-tab"
                            data-bs-placement="top"
                            title="Text to speech"
                            data-bs-toggle="pill"
                            data-bs-target="#tts-voice-file"
                            type="button"
                            role="tab"
                            aria-controls="tts-voice-file"
                            aria-selected={voiceFileUploadMethod === VOICE_FILE_UPLOAD_TYPE.TTS}
                            onClick={(event) => { setVoiceFileUploadMethod(VoiceFileOptionService.getVoiceFileUploadMethod(event.currentTarget.id)); }}
                        >
                            <i className="bi bi-chat-square-text"></i>
                            <p>Text To Speech</p>
                        </button>
                    </li>
                    <li className="nav-item flex-grow-1" role="presentation">
                        <button
                            className={voiceFileUploadMethod === VOICE_FILE_UPLOAD_TYPE.UPLOAD ? "nav-link active d-inline w-100" : "nav-link d-inline w-100"}
                            id="uploadFile-tab"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Upload voice file"
                            data-bs-target="#upload-file"
                            type="button"
                            role="tab"
                            aria-controls="upload-file"
                            aria-selected={voiceFileUploadMethod === VOICE_FILE_UPLOAD_TYPE.UPLOAD}
                            onClick={(event) => { setVoiceFileUploadMethod(VoiceFileOptionService.getVoiceFileUploadMethod(event.currentTarget.id)); }}
                        >
                            <i class="bi bi-upload"></i>
                            <p>Upload File</p>
                        </button>
                    </li>
                </ul>
                <div className="tab-content " id="pills-tabContent">
                    <div
                        className={voiceFileUploadMethod === VOICE_FILE_UPLOAD_TYPE.LIBRARY ? "tab-pane fade show active pt-2" : "tab-pane fade  "}
                        id="file-path"
                        role="tabpanel"
                        aria-labelledby="ttsFile-tab"
                    >
                        <div className="d-flex justify-content-between">
                            <div className="pmivr-title pt-2">Library Audio File</div>
                            <AudioPlayer filePath={voiceFilePath} cssClass={CSS_CLASSES.AUDIO_BUTTON_LARGE}></AudioPlayer>
                        </div>

                        <div className="form-group custom-input mb-3">
                            <label className="pmivr-label pb-2">Base file path</label>
                            <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.BASE_FILE_PATH}>
                                <input
                                    disabled
                                    type="text"
                                    className="form-control pmivr-input pmivr-disabled"
                                    size="50"
                                    value={`${envConfig.REACT_APP_DEFAULT_VOICE_FILE_PATH}`}
                                />
                            </PmivrOverlayTrigger>
                        </div>
                        <div className="form-group custom-input">
                            <PmivrLabel label="File path (File name without .wav extension)" tooltip={TOOLTIP.VOICE_TYPE_PATH_INFO} cssClass={'pb-2'} />
                            <PmivrOverlayTrigger tooltip={TOOLTIP.FILE_PATH_INFO}>
                                <VoiceFilesSelect
                                    onSelect={updateVoiceFilePathState}
                                    selectedItem={voiceFilePath}
                                    selectedLanguage={selectedLanguage}
                                />
                            </PmivrOverlayTrigger>
                        </div>
                    </div>
                    <div
                        className={voiceFileUploadMethod === VOICE_FILE_UPLOAD_TYPE.TTS ? "tab-pane fade show active pt-2  " : "tab-pane fade "}
                        id="tts-voice-file"
                        role="tabpanel"
                        aria-labelledby="createFile-tab"
                    >
                        <TextToSpeechOption
                            ref={ttsRef}
                            element={element}
                            selectedLanguage={selectedLanguage}
                            voiceFileInfo={voiceFileDetails}
                            rightPanelEventHandler={rightPanelEventHandler}
                            // As we have upload button so auto save should be false
                            autoSave={false}
                            resetStates={resetStates}
                            showUploadBtn={true}
                        />
                    </div>
                    <div
                        className={voiceFileUploadMethod === VOICE_FILE_UPLOAD_TYPE.UPLOAD ? "tab-pane fade show active  pt-2 " : "tab-pane fade "}
                        id="upload-file"
                        role="tabpanel"
                        aria-labelledby="uploadFile-tab"
                    >
                        <UploadVoiceFileOption
                            ref={uploadRef}
                            element={element}
                            selectedLanguage={selectedLanguage}
                            voiceFileInfo={voiceFileDetails}
                            rightPanelEventHandler={rightPanelEventHandler}
                            // As we have upload button so auto save should be false
                            autoSave={false}
                            resetStates={resetStates}
                            showUploadBtn={true}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default VoiceFileSettingModal;