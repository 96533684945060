import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { CSS_CLASSES, TASK_ICONS } from "../../constants/css-classes";
import { MESSAGES, TOOLTIP } from "../../constants/messages";
import { FLOW_STATUS, FLOW_TYPE_ID } from "../../constants/flow.js";
import { DNID_NUMBER_STATUS } from "../../constants/dnid";
import { FIELD_TYPES } from "../../constants/field-types";
import { APP_PAGES } from "../../constants/app-pages";
import { APP_CONFIG } from "../../config/config";

import PmivrSnackBar from "../../components/common/dialog/pmivr-snackbar";
import PmivrTooltip from "../../components/common/tooltip/pmivr-tooltip";
import PmivrAppLayout from "../../components/common/layouts/pmivr-app-layout.js";
import { PmivrDialog } from "../../components/common/dialog/pmivr-dialog.js";
import DnidVariablesModal from "./components/dnid-variables-modal.js";

import {
  updateDraft, updateSelectedFlowType, updateVersion, updateSelectedFlowTypeInformation,
  updateDeploymentEnv
} from "../../redux/actions/client.action";
import { updateChatFlowInfo } from "../../redux/actions/interactive-design.action";
import { updateVoiceFilePrefixInfo } from "../../redux/actions/voice-file.action";
import AppUtil from "../../util/app.util";
import StringUtil from "../../util/string.util.js";

import WizardService from "../../services/wizard.service";
import ClientService from "../../services/client.service";
import UserService from "../../services/user.service";
import FlowService from "../../services/flow.service";
import ConfigService from "../../services/config.service.js";

/**
 * The wizard for configuring the client for IVR.
 * The client can answer the questions for configurations needed to be done for IVR.
 * @returns {React.Component} Html element containing questions with checkbox and radio buttons to render 
 */
const Wizard = () => {
  const location = useLocation();
  // get the value of disabled the wizard from query param
  const searchParams = new URLSearchParams(location.search);
  // navigating to pages
  const navigate = useNavigate();
  // updates the state in redux
  const dispatch = useDispatch();
  // using the open method from the snackbar component
  const snackbarRef = useRef();
  // getting latest state from redux
  let { businessCode, versionId, flowType, isVerifiedClient, selectedFlowTypeInfo, deploymentEnvironment } = useSelector(state => state.client);
  const { chatFlowId } = useSelector(state => state.interactiveDesign);
  // questions to be display in wizard screen
  const [questionnaire, setQuestionnaire] = useState([]);
  // to store numbers
  const [numberPool, setNumberPool] = useState([]);
  const [autoLayout, setAutoLayout] = useState(false);
  // list of dnid added by user and its details
  const [dnidRows, setDnidRows] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  let counter = 1; // counter variable is to give dynamic id to the radio buttons and checkboxes
  /**
   * showWarning: show the warning
   * formDisabled: disable the wizard form
   * clientFlowExists: if coming from diagram page, then we get clientFlowExists as true, otherwise we get false
   * savedDnidLength: save the length of already saved dnids so as to maintain list of new dnid enterd on UI
   * selectedDnidRow: Info of dnid of the selected row in all the rows of dnid
   */
  const [uiState, setUiState] = useState({
    formDisabled: false, showWarning: false, clientFlowExists: searchParams.get('clientFlowExists') === "true" || false,
    showDnidValuesDialog: false, savedDnidLength: 0, selectedDnidRow: null, enableExt: false, selectedDnidIndex: 0
  });

  // if businessCode name not found in the state, then read it from path url
  if (!businessCode) {
    businessCode = location.pathname.split("/").at(-1);
  }

  useEffect(() => {
    // check if token exists if not then redirect to login page.
    const currentUser = UserService.getCurrentUser();
    if (!currentUser?.token) {
      navigate(APP_PAGES.LOGIN);
    }
    // check the given businessCode is newly and load the wizard else show alert and redirect to home page
    checkBusinessCodeAndLoadWizard(businessCode);
    if (uiState?.clientFlowExists) {
      setDnidRows(selectedFlowTypeInfo?.dnid);
      setUiState({ ...uiState, savedDnidLength: selectedFlowTypeInfo?.dnid?.length });
    }
  }, []);

  useEffect(() => {
    // Update the button's disabled state
    setIsButtonDisabled(isSaveWizardDisabled(dnidRows));
  }, [dnidRows]);

  /**
   * Check if save wizard and update wizard button to be disabled
   * @param {Array<{{dnid, lext,filledVars}}>} dnidRows 
   * @returns 
   */
  const isSaveWizardDisabled = (dnidRows) => {
    // Check if any dnid is empty or any lext is empty or a single space when showExt is true
    const hasInvalidEntry = dnidRows.some(item =>
      item.dnid === '' ||
      (item.showExt && (item.lext === '' || item.lext === ' '))
    );

    // Count occurrences of dnid where lext is empty
    const dnidCounts = dnidRows.reduce((acc, item) => {
      if (item.lext === '') {
        acc[item.dnid] = (acc[item.dnid] || 0) + 1;
      }
      return acc;
    }, {});
    const hasDuplicateDnidWithEmptyLext = Object.values(dnidCounts).some(count => count > 1);

    return hasInvalidEntry || hasDuplicateDnidWithEmptyLext;
  };

  /**
   * Add new dnid row to configure new dnid and its variables
   */
  const addDnidRow = () => {
    setDnidRows([...dnidRows, { dnid: '', transferChannelCode: "", transferNumber: "", showExt: false }]);
  };

  /**
   * Select the dnid number
   * It sets the state value for extension numbers and error messages
   * @param {number} dnid phnNumber selected 
   */
  function handleSelectDnid(dnid, dnidIndex) {
    if (dnid !== "") {
      // save the numberPool info from array using index
      // get the index of dnid selected
      const index = numberPool.findIndex(obj => obj.phoneNumber === dnid);
      const updatedRows = [...dnidRows];
      if (numberPool[index].rangeStart || numberPool[index]?.lext) {
        updatedRows[dnidIndex].showExt = true;
      } else {
        updatedRows[dnidIndex].showExt = false;
      }
      updatedRows[dnidIndex].lext = "";
      updatedRows[dnidIndex].dnid = dnid;
      updatedRows[dnidIndex].transferChannelCode = "";
      updatedRows[dnidIndex].transferNumber = "";
      setDnidRows(updatedRows);
    } else {
      const updatedRows = [...dnidRows];
      updatedRows[dnidIndex] = { dnid: '', lext: '', transferChannelCode: "", transferNumber: "", showExt: false };
      setDnidRows(updatedRows);
    }
  }

  /**
   * Check if the current businessCode is new and load the wizard else show alert msg and redict to home page
   * @param {string} businessCode Name of businessCode
   */
  const checkBusinessCodeAndLoadWizard = async (businessCode) => {
    // user can directly open the wizard page but on UI no flow was selected
    // so if no flow is there,then redirect to home page 
    if (!selectedFlowTypeInfo?.flowName) {
      alert(MESSAGES.ERR.NO_FLOW_SELECTED);
      return navigate(APP_PAGES.HOME);
    }

    /**
      * Checking whether the deployment environments are configured or not.
      * If not configured, then run as normal, no need to verify for the environment on which businessCode is saved.
      * If configured, then giving preferance to the query param and if we found envKey in the query param, then save it in redux,
      * and verify client in that environment.
      * If envKey is not found in query param, then check the env in redux (saved on home page) and verify the client.
      * if envKey is not found in both queryParam and redux, then show error on the screen.
      * 
      * EnvKey will be set in the query param only when we straight hit the url of the page.
      * 
      * in redux, isVerifiedClient : true/false
      */

    try {
      const envKeyParam = searchParams.get('envKey');
      const isDeploymentEnvironmentExists = await ConfigService.isDeploymentEnvironmentExists();
      if (isDeploymentEnvironmentExists && AppUtil.isValueValid(envKeyParam)) {
        dispatch(updateDeploymentEnv({ deploymentEnvironment: envKeyParam }));
        // if user comes here directly through url, then verifying the client
        const clientVerificationResponse = await ClientService.getClientVerification(businessCode);
        // clientVerificationResponse: {data: true}
        isVerifiedClient = clientVerificationResponse?.data || false;
      } else {
        if (isDeploymentEnvironmentExists) {
          if (!AppUtil.isValueValid(deploymentEnvironment)) {
            alert(MESSAGES.ERR.DEPLOYMENT_ENVIRONMENT_NOT_FOUND);
            navigate(APP_PAGES.HOME);
            return;
          }
        }
        if (!isVerifiedClient) {
          // if we directly hit the url and verifyClient not found in redux, then verifying the client
          const clientVerificationResponse = await ClientService.getClientVerification(businessCode);
          // clientVerificationResponse: {data: true}
          isVerifiedClient = clientVerificationResponse?.data || false;
        }
      }
    } catch (err) {
      alert(MESSAGES.ERR.BUSINESS_CODE_NOT_EXISTS_ENVIRONMENT);
      navigate(APP_PAGES.HOME);
      return;
    }

    if (isVerifiedClient) {
      // adding the businessCode being currently searched in local storage for further use
      const basicFlowInfo = FlowService.getBasicFlowInfo();
      basicFlowInfo.businessCode = businessCode;
      FlowService.setBasicFlowInfo(basicFlowInfo);
      const filters = { flowName: selectedFlowTypeInfo.flowName, flowTypeId: selectedFlowTypeInfo.flowTypeId }
      // user may hit wizard page for existing businessCode so we neeed to check this
      //getting the flowInfo as per businessCode
      const flowInfo = await ClientService.getFlowInfo(businessCode, filters);
      // taking out draft docs info and published docs info from flowInfo cache
      const { draft, published } = flowInfo;
      // drafts docs or published docs found, it means that it is an existing businessCode (redirect to home page)
      // not found, it means that it is a new businessCode and we need to onboard it
      if ((draft?.length > 0 || published?.length > 0) && !uiState.clientFlowExists) {
        alert(MESSAGES.EXISTING_BUSINESS_CODE);
        navigate(APP_PAGES.HOME);
      } else {
        loadWizard();
      }
    } else {
      alert(MESSAGES.CONFIG_KEYS_NOT_FOUND);
      navigate(APP_PAGES.HOME);
    }
  };

  /**
   * Renders the child fields (text fields, radio buttons, etc.) on the screen
   * @param {Array} subQuestions List of questions
   * @param {Array} questions List of questions
   * @returns {React.Component} Html element to render 
   */
  const getChildrenField = (subQuestions, questions) => {
    if (!subQuestions) {
      return <></>;
    }
    return subQuestions.map((subQuestion, _index) => {
      return (
        <ol key={subQuestion.title} className="sub-questions">
          <li>
            <div className="pmivr-label">
              <label className="mb-3">{subQuestion.title}</label>
            </div>
            {getField(questions, subQuestion)}
          </li>
        </ol>
      );
    });
  };

  /**
   * Renders the child fields in case of check box on the screen
   * @param {Array} subQuestions List of child questions
   * @param {Array} questions List of questions
   * @param {Array} questionValue Value of the question selected
   * @param {Array} optionValue value of options that is display, it is not the selected value of question
   * @returns {React.Component} Html element to render 
   */
  const getCheckboxChildren = (subQuestions, questions, questionValue, optionValue) => {
    return subQuestions.map((subQuestion, _index) => {
      // to check for the child question belonging to its parent question
      const isQuestionChild = subQuestion.key === optionValue;
      const isVoiceTypeIncluded = questionValue.includes(subQuestion.key);
      return (
        isQuestionChild &&
        <ol key={subQuestion.title} className={`${isVoiceTypeIncluded ? "sub-questions" : "sub-questions pmivr-disabled"}`}>
          <li>
            <div className="pmivr-label">
              <label className="mb-3">{subQuestion.title}</label>
            </div>
            {getField(questions, subQuestion)}
          </li>
        </ol >
      );
    });
  };

  /**
   * Get the Questions list
   * @param {Array} questions List of questions
   * @returns {Element} Questions in HTML format
   */
  const getQuestions = (questions) => {
    return questions.map((question, index) => {
      return (
        <>
          <li key={question.title} disabled={(!question?.isEditable && uiState?.clientFlowExists)}>
            <span>{index + 1}</span>
            <div className="list  pt-1 p-3">
              <div className="form-group ">
                <div className="pmivr-label">
                  <label className="mb-3">
                    {question.heading || question.title}
                  </label>
                </div>
                {question.heading && question.title ? (
                  <div className="pmivr-label">
                    <label className="mb-3">{question.title}</label>
                  </div>
                ) : (
                  <></>
                )}
                <div className="col-md-12">{getField(questions, question)}</div>
              </div>
            </div>
          </li>
        </>
      );
    });
  };

  /**
   *  Update the checkbox value
   * @param {Object} question
   * @param {Array} questions
   * @param {string} newValue
   */
  const updateInputValueCheck = (question, questions, newValue) => {
    const isNewValueInValues = question.value.find((x) => x === newValue);
    if (isNewValueInValues) {
      question.value = question.value.filter((n) => {
        return n !== newValue;
      });
    } else {
      question.value = [...question.value, newValue];
    }
    setQuestionnaire([...questions]);
  };

  /**
   *  Update the field value like text, radio, dropdown
   * @param {Object} question
   * @param {Array} questions
   * @param {string} newValue
   */
  const updateInputValue = (question, questions, newValue) => {
    question.value = newValue;
    setQuestionnaire([...questions]);
  };

  /**
   * Get the HTML code for the field to be shown, as per field type,
   * Like HTML code checkbox, radiobutton, text field, etc
   * @param {Object} question
   * @param {Array} questions
   * @param {number} index
   *  @returns {React.Component} Html element to render 
   */
  const getField = (questions, question = {}) => {
    if (!question.options && !question.nextchild) {
      if (question.fieldType !== "text") {
        return <></>;
      }
    }

    let fieldContent = "";

    switch (question.fieldType) {
      case FIELD_TYPES.DROPDOWN: {
        fieldContent = (
          <select
            className="pmivr-select select-sm custom-drop "
            value={question.value}
            onChange={(e) => {
              updateInputValue(question, questions, e.target.value);
            }}
          >
            {question.options.map((option, _keyIndex) => {
              return (
                <option key={option.value} value={option.value}>
                  {option.name}
                </option>
              );
            })}
          </select>
        );
        break;
      }
      case FIELD_TYPES.RADIO: {
        fieldContent = (
          <>
            {question.options.map((option, optionIndex) => {
              return (
                <div key={optionIndex} className="form-check pmivr-check-radio form-custom-inline">
                  <input
                    className="form-check-input"
                    id={counter}
                    type="radio"
                    checked={question.value === option.value ? "checked" : ""}
                    value={option.value}
                    onChange={(e) => {
                      updateInputValue(question, questions, e.target.value);
                    }}
                  />
                  <label className="form-check-label" htmlFor={counter++}>
                    {option.name}
                  </label>
                </div>
              );
            })}
          </>
        );
        break;
      }
      case FIELD_TYPES.CHECKBOX: {
        fieldContent = (
          <>
            {question.options.map((option, _index) => {
              return (
                <div className="row">
                  <div className="col-md-1">
                    <div className="form-check pmivr-check-radio form-custom-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id={counter}
                        checked={
                          (question.value || []).indexOf(option.value) > -1
                            ? "checked"
                            : ""
                        }
                        value={option.value}
                        onChange={(e) => {
                          updateInputValueCheck(question, questions, e.target.value);
                        }}
                      />
                      <label className="form-check-label" htmlFor={counter++}>
                        {option.name}
                      </label>
                    </div>
                  </div>
                  <div className="col-md-11">
                    {(question.fieldType === "checkbox" && (question.children).length) ? (
                      getCheckboxChildren(question.children, questions, question.value, option.value)
                    ) : (
                      <></>
                    )}
                  </div>
                </div>

              );
            })}
          </>
        );
        break;
      }
      case FIELD_TYPES.TEXT: {
        fieldContent = (
          <div className=" form-check-inline">
            <input
              className="form-control input-sm"
              id="inlineText1"
              type="text"
              name="fav3"
              disabled={StringUtil.toBoolean(question.disabled)}
              value={question.value}
              onChange={(e) => {
                updateInputValue(question, questions, e.target.value);
              }}
            />
          </div>
        );
        break;
      }
      default:
        fieldContent = <></>;
        break;
    }

    return (
      <>
        {fieldContent}
        {/* in case if it is not checkbox then render child questions*/}
        {(question.fieldType !== "checkbox" && (question.childRenderOption === question.value)) ? (
          getChildrenField(question.children, questions)
        ) : (
          <></>
        )}
        {/* if checkbox then it gives array value so we need to check its length */}

      </>
    );
  };

  /**
   * Called when navigate to diagram page
   * @param {string} businessCode Name of businessCode
   * @param {string} docType Status of flow
   * @param {string} versionId Version id of the doc
   */
  const navigateToDiagram = async (businessCode, docType, versionId) => {
    navigate(`${APP_PAGES.DIAGRAM}/${businessCode}/${docType}/${versionId}?autoLayout=${autoLayout}`);
  };

  /**
   * Extract the question Id and it's value from questionaaire
   * @param {Array} questions Set of question in array form
   * @returns {Array} Return set of question id and it's value in list
   */
  function extractQuestionsValues(questions) {
    const questionsValues = [];
    questions.forEach((question) => {
      let childrenValues = [];
      // child question are present then get the value of child question
      if (question.children) {
        question.children.forEach((subQuestion) => {
          // in case of child question use recursion and add it's value to list( childrenValues )
          childrenValues = childrenValues.concat(
            extractQuestionsValues([subQuestion])
          );
        });
      }

      questionsValues.push({
        questionId: question.questionId,
        value: question.value,
        callVariableName: question.callVariableName,
        key: question.key,
        childQuestionId: question.childQuestionId,
        children: childrenValues,
        validation: question.validation
      });
    });
    return questionsValues;
  }

  /**
   * Validating the question values of the wizard
   * @param {Array} questionsValues Array of wizard questions with their values
   * @returns {Boolean} true, if validated successfully. Otherwise, return false
   */
  const validateQuestions = (questionsValues) => {
    let isValid = true;
    // Validating each and every question that is configured for validation
    for (const question of questionsValues) {
      if (question?.validation) {
        for (const validation of question.validation) {
          if (validation?.type === 'required') {
            isValid = AppUtil.isValueValid(question.value);
          }
          if (!isValid) {
            // question value is not valid, then break from inner loop
            break;
          }
        }
        if (!isValid) {
          // question value is not valid, then break from outer loop
          break;
        }
      }
    }
    return isValid;
  }

  /**
   * Saves the wizard configuration for businessCode
   * Upload the XML to server
   * @param {Array} questions
   */
  const saveWizard = async (questions) => {
    setUiState({ ...uiState, formDisabled: true });
    try {
      // extract question id and selected values from questions
      const questionsValues = extractQuestionsValues(questions);
      const dnidInfo = dnidRows.map(({ dnid, lext, transferChannelCode, transferNumber, filledVars }) => ({ dnid, lext, transferNumber, transferChannelCode, filledVars }));
      const flowTypeInfo = {
        dnid: dnidInfo,
        flowName: selectedFlowTypeInfo.flowName,
        flowTypeId: selectedFlowTypeInfo.flowTypeId, wizardConfig: questionsValues
      };
      // parent flow xml that is sent to the backend in case of making other flows as custom
      if (selectedFlowTypeInfo?.parentFlowXml) {
        flowTypeInfo.parentFlowXml = selectedFlowTypeInfo?.parentFlowXml;
      }
      // if redirectedXml is their then update the redux
      // redirected xml is xml coming from another env on saving wizard we update redux
      if (selectedFlowTypeInfo?.redirectedXml) {
        flowTypeInfo.redirectedXml = selectedFlowTypeInfo?.redirectedXml;
      }
      if (selectedFlowTypeInfo?.chatFlowId) {
        flowTypeInfo.chatFlowId = selectedFlowTypeInfo?.chatFlowId;
      }
      dispatch(updateSelectedFlowTypeInformation({ selectedFlowTypeInfo: flowTypeInfo }));
      // check at least one language is selected
      if (!validateQuestions(questionsValues)) {
        // opening the snackbar
        snackbarRef.current.open(MESSAGES.ATLEAST_SELECT_ONE_LANG);
        return;
      }
      // save Flow information
      await saveFlowInfo(questionsValues);
      // update redux state for voice file suffix configured by client
      getVoiceFilePrefix(questionsValues);

      // opening the snackbar
      snackbarRef.current.open(MESSAGES.SAVED_SUCCESSFULLY);
      // get the flow information from cache and redirect to diagram page
      const flowInformation = await ClientService.getFlowInfo(businessCode);
      // giving timeout to remain on the same screen for displaying message
      setTimeout(() => {
        navigateToDiagram(flowInformation.businessCode, flowInformation.status, flowInformation.docVersionId);
      }, APP_CONFIG.MESSAGE_TIMEOUT);

      if (chatFlowId) {
        dispatch(updateChatFlowInfo({ chatFlowId: "" }));
      }
    } catch (error) {
      setUiState({ ...uiState, formDisabled: false });
      // opening the snackbar
      if (snackbarRef?.current) {
        snackbarRef.current.open(MESSAGES.ERR.WIZARD_SAVE);
      }
    }
  };

  /**
   * Get the voice file suffix to append in base path and set it to redux
   * @param {Array} wizardConfig Array of questions
   */
  const getVoiceFilePrefix = (wizardConfig) => {
    if (wizardConfig.length) {
      const voicePathData = wizardConfig[0]?.children;
      const voiceFilePrefixInfo = {};
      voicePathData.forEach((item) => {
        if (!item?.childQuestionId) {
          voiceFilePrefixInfo[item.key] = item.value;
        }
      });
      dispatch(updateVoiceFilePrefixInfo({ voiceFilePrefixObj: voiceFilePrefixInfo }));
    }
  }

  // save information of flow in S3 and update flow info cache
  async function saveFlowInfo(questionsValues) {
    let configureClientResponse;
    let docVersionId = uiState.clientFlowExists ? versionId : "";
    const status = uiState.clientFlowExists ? flowType : FLOW_STATUS.DRAFT;
    const flowInfo = {
      status: status, businessCode, parentVersionId: docVersionId, versionId: docVersionId,
      isDeleted: false, flowName: selectedFlowTypeInfo.flowName, flowTypeId: selectedFlowTypeInfo.flowTypeId
    };

    // In case of custom flow if it is created from any other flow, then append the parent flow xml in flowInfo
    const parentFlowXml = selectedFlowTypeInfo?.parentFlowXml;
    if (parentFlowXml) {
      flowInfo.xml = parentFlowXml;
    }
    // send client's setting in for configure client
    const dnidInfo = dnidRows.map(({ dnid, lext, transferChannelCode, transferNumber, filledVars }) => ({ dnid, lext, transferNumber, transferChannelCode, filledVars }));
    const clientInfo = { dnid: dnidInfo, questionsValues };
    if (!uiState.clientFlowExists) {
      // client is not already configured
      // so it will configure client in S3 and save flow information in database
      if (chatFlowId) {
        setAutoLayout(true);
        // create flowInfo for interactive design
        const flowInfo = {
          status: FLOW_STATUS.DRAFT, businessCode,
          parentVersionId: "", versionId: "", isDeleted: false,
          flowName: selectedFlowTypeInfo.flowName, flowTypeId: FLOW_TYPE_ID.INTERACTIVE_DESIGN_FLOW,
          dnid: dnidInfo, questionsValues, chatFlowId
        };
        // convert json to xml and configure the client
        const response = await FlowService.interactiveJsonToXml(flowInfo);
        docVersionId = response?.versionId;
      } else {
        configureClientResponse = await ClientService.configureClient(flowInfo, clientInfo);
        docVersionId = configureClientResponse?.versionId;
      }
    } else {
      // client is already configured and updating wizardConfig
      await ClientService.saveWizardConfig(businessCode, questionsValues);
    }

    dispatch(updateVersion({ versionId: docVersionId }));
    dispatch(updateSelectedFlowType({ flowType: status }));
    const basicFlowInfo = FlowService.getBasicFlowInfo();
    basicFlowInfo.docVersionId = docVersionId;
    basicFlowInfo.flowType = status;
    basicFlowInfo.flowName = selectedFlowTypeInfo.flowName;
    basicFlowInfo.flowTypeId = selectedFlowTypeInfo.flowTypeId;
    FlowService.setBasicFlowInfo(basicFlowInfo);

    // getting the flow information
    const flowInformation = await ClientService.getFlowInfo(businessCode);
    dispatch(updateDraft({ draft: flowInformation.draft }));
  }

  /**
   * Map the client wizard config values with the questions and its default values
   * Map the values with questions based on question Id.
   * @param {Array<{questionId, children, title, value, flowTypeId, childRenderOption}>} wizardQuestions 
   * @param {Array<{questionId, children, title, value, flowTypeId, childRenderOption}>} clientWizardConfig 
   */
  function setClientWizard(wizardQuestions, clientWizardConfig) {
    // Iterate over each item in the clientWizardConfig array
    clientWizardConfig.forEach(clientWizardConfigItem => {
      // Find the index of the corresponding item in the wizardQuestions array based on the questionId
      const index = wizardQuestions.findIndex(wizardQuest => wizardQuest.questionId === clientWizardConfigItem.questionId);
      if (index !== -1) {
        // If the item is found in the wizardQuestions array
        const wizardQuest = wizardQuestions[index];
        if (clientWizardConfigItem?.children?.length) {
          // If the second item has children
          clientWizardConfigItem.children.forEach((child, childIndex) => {
            // Iterate over each child
            if ('key' in child) {
              let firstChildIndex;
              // for editing the wizard and to show answers on UI with questions we have this child question id
              if ("childQuestionId" in child) {
                firstChildIndex = wizardQuest.children.findIndex(firstChild =>
                  firstChild.childQuestionId === child.childQuestionId && firstChild.key === child.key
                );
              } else {
                // If the child has a key property, find its index in the first item's children
                firstChildIndex = wizardQuest.children.findIndex(firstChild => firstChild.key === child.key);
              }
              if (firstChildIndex !== -1) {
                // If the child is found in the first item's children, merge its properties with the first item's child
                const firstChild = wizardQuest.children[firstChildIndex];
                Object.assign(firstChild, child);
              }
            } else {
              // If the child does not have a key property, assign its properties to the corresponding child 
              //in the first item's children
              if (childIndex < wizardQuest.children.length) {
                const firstChild = wizardQuest.children[childIndex];
                Object.assign(firstChild, child);
              }
            }
          });
        }
        // Remove the children property from the second item and assign its properties to the corresponding item
        // in the first array
        const { children, ...rest } = clientWizardConfigItem;
        Object.assign(wizardQuest, rest);
      }
    });
    // Return the updated first array
    return wizardQuestions;
  }

  /**
   * Get the wizard from db, saved while setuping the IVR for the client,
   * And then update the updated values.
   */
  async function loadWizard() {
    try {
      // get the questions, available dnid numbers and client wizard config
      const wizardP$ = [
        WizardService.getWizard(selectedFlowTypeInfo.flowTypeId),
        ClientService.getWizardConfig({ businessCode, flowName: selectedFlowTypeInfo?.flowName }),
        ClientService.getDnidNumberPool(DNID_NUMBER_STATUS.AVAILABLE)
      ];
      let wizardInfo = await Promise.all(wizardP$);
      // get question's value from default value and selected user values
      const wizardConfig = wizardInfo[1].length ? wizardInfo[1] : [];

      setNumberPoolList(wizardInfo[2]?.data);
      wizardInfo = formatQuestions(wizardInfo[0], wizardConfig);
      // update wizardInfo
      if (uiState.clientFlowExists) {
        // set the client wizard and display in disabled form
        const wizardDisplayQuestions = setClientWizard(wizardInfo, wizardConfig);
        setQuestionnaire(wizardDisplayQuestions);
        setUiState({ ...uiState, formDisabled: true, savedDnidLength: selectedFlowTypeInfo?.dnid?.length });
      } else {
        setQuestionnaire(wizardInfo);
      }
    } catch (err) {
      // opening the snackbar
      if (snackbarRef?.current) {
        snackbarRef.current.open(MESSAGES.ERR.WIZARD_LOAD);
      }
      setUiState({ ...uiState, clientFlowExists: false });
    }
  }

  /**
   * Update number pool in state
   * @param {Array} numberPoolList 
   */
  function setNumberPoolList(numberPoolList) {
    // if dnid is not present show warning
    if (!numberPoolList.length) {
      setUiState({ ...uiState, showWarning: true });
    }
    setNumberPool(numberPoolList);
  }

  /**
   * Fetch question's value either from default or user selected values and return updated questionaire
   * @param {Array} questions Set of question in array form
   * @param {Array} wizardConfig Set of user selected question's value in array form
   * @returns {Array} Return updated questions
   */
  function formatQuestions(questions, wizardConfig) {
    // set question's value either from default or user selected values
    questions.forEach((question) => {
      question.value = question.default || "";
      // case where checkbox default value is []
      if (question.fieldType === FIELD_TYPES.CHECKBOX) {
        question.value = question.default ? question.default : [];
      }
      // question's value present in wizardConfig then update question's value
      // seperate function is made beacuse of sonarLint warning giving complexity error.
      updateQuestionsValue(wizardConfig, question);
    });
    return questions;
  }

  /**
   * Update the question in the wizard config
   * @param {Array} wizardConfig Set of user selected question's value in array form
   * @param {Object} question Questions that have been updated
   */
  function updateQuestionsValue(wizardConfig, question) {
    // question's value present in wizardConfig then update question's value
    const questionInfo = wizardConfig.find(
      (questionInfo) => questionInfo.questionId === question._id
    );
    if (questionInfo) {
      question.value = questionInfo.value;
      if (question.children) {
        question.children.forEach((subQuestion) => {
          const valueOfChilden = questionInfo.children
            ? questionInfo.children
            : [];
          formatQuestions([subQuestion], valueOfChilden);
        });
      }
    }
  }

  /**
   * Edit the wizard to make changes like language selection 
   * if one language selected and want another also
   */
  const updateWizard = async () => {
    try {
      // get the question values
      const questionsValues = extractQuestionsValues(questionnaire);
      const flowInfo = {
        businessCode, flowName: selectedFlowTypeInfo?.flowName,
        flowTypeId: selectedFlowTypeInfo?.flowTypeId, wizardConfig: questionsValues
      };
      // update the wizard
      const dnidInfo = dnidRows.map(({ dnid, lext, transferChannelCode, transferNumber, filledVars }) => ({ dnid, lext, transferNumber, transferChannelCode, filledVars }));
      // send only those dnids who are newly added to update the status in number pool
      const oldDnidInfo = dnidInfo.slice(0, uiState.savedDnidLength);
      const newDnidInfo = dnidInfo.slice(uiState?.savedDnidLength);
      const dnidInformation = { oldDnidInfo, newDnidInfo };
      await ClientService.updateWizardConfig(flowInfo, dnidInformation);
      const newFlowInformation = { ...selectedFlowTypeInfo, wizardConfig: questionsValues, dnid: dnidInfo };
      dispatch(updateSelectedFlowTypeInformation({ selectedFlowTypeInfo: newFlowInformation }));

      snackbarRef.current.open(MESSAGES.WIZARD_UPDATE);
      setUiState({ ...uiState, formDisabled: true, savedDnidLength: dnidRows?.length });
    } catch (err) {
      if (snackbarRef?.current) {
        snackbarRef.current.open(MESSAGES.ERR.WIZARD_UPDATE_ERR);
      }
    }
  }

  /**
   * By default wizard will be disabled so on button click we enable the wizard
   * so that user can make changes and on cancel it will be again disabled
   */
  const toggleEdit = () => {
    setUiState({ ...uiState, formDisabled: !uiState.formDisabled });
  }

  /**
   * Remove the dnid row on cross button
   * @param {number} index index of the row in dnid rows
   */
  const handleRemoveDnid = (index) => {
    const updatedRows = [...dnidRows];
    updatedRows.splice(index, 1);
    setDnidRows(updatedRows);
  };

  /**
   * Handles the opening of dialog with values of selected row
   * @param {*} dnidInfo dnidInfo of the selected row to show in dialog 
   * @param {*} dnidIndex index of the selected row in dnid rows
   */
  const handleDialogOpen = (dnidInfo, dnidIndex) => {
    let enableExt = false;
    if (dnidInfo.dnid) {
      const index = numberPool.findIndex(obj => obj.phoneNumber === dnidInfo.dnid);
      const updatedRows = dnidRows.map(row => ({ ...row }));
      if (index !== -1 && (numberPool[index].rangeStart || numberPool[index]?.lext)) {
        updatedRows[dnidIndex].showExt = true;
        // Create a copy of dnidInfo if it is not extensible
        if (!Object.isExtensible(dnidInfo)) {
          dnidInfo = { ...dnidInfo };
        }
        dnidInfo["showExt"] = true;
        if (dnidIndex > uiState.savedDnidLength - 1) {
          enableExt = true;
        }
      } else {
        updatedRows[dnidIndex].showExt = false;
      }
      setDnidRows(updatedRows);
      setUiState({ ...uiState, showDnidValuesDialog: true, selectedDnidRow: dnidInfo, enableExt, selectedDnidIndex: dnidIndex });
    } else {
      setUiState({ ...uiState, selectedDnidRow: null, selectedDnidIndex: dnidIndex });
      snackbarRef.current.open(MESSAGES.DNID_INFO.SELECT_DNID);
    }
  }

  /**
   * It updates the dnid rows array with the new values
   * @param {{dnidRowValues, newDnidValues, lext, dnidIndex}} newDnidRowInfo new info of the dnid to update
   */
  const updateDnidRowValues = (newDnidRowInfo) => {
    const { dnidRowValues, newDnidValues, lext, dnidIndex, transferChannelCode, transferNumber } = newDnidRowInfo;
    const updatedDnidRows = dnidRows.map((row, index) => {
      if (row.dnid === newDnidValues.dnid && row.lext === newDnidValues.lext && index === dnidIndex) {
        return {
          ...row,
          filledVars: dnidRowValues,
          lext, transferChannelCode, transferNumber
        };
      }
      return row;
    });

    // Assuming you have a state setter for dnidRows
    setDnidRows(updatedDnidRows);
  }

  return (
    <>
      <PmivrAppLayout showLeftBar={uiState.clientFlowExists} showFooter={!uiState.clientFlowExists}>
        <PmivrSnackBar ref={snackbarRef} />
        <PmivrDialog showDialog={uiState.showDnidValuesDialog} closeDialog={() => setUiState({ ...uiState, showDnidValuesDialog: false })}
          title={"Add values for DNID"} cssClass={"dnid-modal"}
          message={<DnidVariablesModal updateDnidRowValues={updateDnidRowValues} dnidNumbersList={dnidRows} numberPool={numberPool}
            closeAction={() => setUiState({ ...uiState, showDnidValuesDialog: false })} dnidRowInfo={uiState.selectedDnidRow}
            clientFlowExists={uiState.clientFlowExists} enableExt={uiState.enableExt} dnidIndex={uiState.selectedDnidIndex} />}
          footer={<></>} />
        <div className="pmivr-container">
          <div className="row border-bottom  pb-3 pt-3 ">
            <div className="col-lg-6">
              <div className={uiState.clientFlowExists
                ? `px-2 pmivr-breadcrumb-list m-2 ${CSS_CLASSES.HIDE_DISPLAY}`
                : `px-2 pmivr-breadcrumb-list m-2 ${CSS_CLASSES.INLINE_DISPLAY}`}>
                {deploymentEnvironment ? `${deploymentEnvironment} : ` : ``}{selectedFlowTypeInfo.flowName} : <PmivrTooltip message={TOOLTIP.NAVIGATION_LINKS.HOME}>
                  <Link to={`/home`}>Home</Link></PmivrTooltip>/{businessCode}/wizard/
              </div>
              <div className={uiState.clientFlowExists
                ? `px-2 pmivr-breadcrumb-list m-2 ${CSS_CLASSES.INLINE_DISPLAY}`
                : `px-2 pmivr-breadcrumb-list m-2 ${CSS_CLASSES.HIDE_DISPLAY}`}>
                {deploymentEnvironment ? `${deploymentEnvironment} : ` : ``}{selectedFlowTypeInfo.flowName} : <PmivrTooltip message={TOOLTIP.NAVIGATION_LINKS.HOME}>
                  <Link to={`/home`}>Home</Link></PmivrTooltip>/
                <PmivrTooltip message={TOOLTIP.NAVIGATION_LINKS.FLOWS}>
                  <Link to={`${APP_PAGES.CLIENT_FLOWS}/${businessCode}`}>{businessCode}</Link>
                </PmivrTooltip>/
                <PmivrTooltip message={TOOLTIP.NAVIGATION_LINKS.DIAGRAM}>
                  <Link to={`/diagram/${businessCode}/${flowType}/${versionId}`}>
                    {flowType}
                  </Link>
                </PmivrTooltip>
                /wizard/
              </div>
            </div>
          </div>

          <div className="pmivr-wizard-data pmivr-scroll scroll">
            <div>
              {uiState.showWarning ? <h6 className="warning">{MESSAGES.DNID_UNAVAILABLE}</h6> : <></>}
            </div>
            <div>
            </div>
            <div className="info-box d-flex">
              {/* businessCode */}
              <label className="label pt-1">BUSINESS CODE | </label>
              <label className="label-value px-2 pt-1">
                {businessCode}
              </label>
              {(uiState?.clientFlowExists && uiState.formDisabled) &&
                <div className="ms-auto me-2 mt-2">
                  <PmivrTooltip message={TOOLTIP.UPDATE_WIZARD}>
                    <button disabled={!uiState?.formDisabled}
                      className="pmivr-btn-app" style={{ marginLeft: "10px" }}
                      onClick={() => toggleEdit()}>Edit</button>
                  </PmivrTooltip>
                </div>
              }
              {uiState?.clientFlowExists && !uiState.formDisabled && (
                <div className="ms-auto me-2 mt-2">
                  <button className="pmivr-btn-cancel me-1"
                    onClick={() => toggleEdit()} >
                    Cancel
                  </button>
                  <button className="pmivr-btn-app"
                    disabled={isButtonDisabled}
                    onClick={() => updateWizard()} >
                    Save
                  </button>
                </div>
              )}
            </div>

            <div className="info-box dnid-info-box">
              <div className="d-flex">
                <h6 className="pmivr-title mb-4">Configure DNID for the Flow</h6>
                <button className="pmivr-add-option ms-auto mb-auto" onClick={addDnidRow} disabled={uiState?.formDisabled}>
                  Add DNID<i className="ms-1">+</i>
                </button>
              </div>
              {dnidRows?.length ?
                <>
                  <table className="table pmivr-table header-fixed table-body-block border mt-2">
                    <thead>
                      <tr>
                        <th width="20%" className="text-center">DNID
                          <PmivrTooltip message={TOOLTIP.INFO.DNID}>
                            <i className={`${TASK_ICONS.DISPLAY_INFO} px-2`}></i>
                          </PmivrTooltip>
                        </th>
                        <th width="20%" className="text-center">LEXT
                          <PmivrTooltip message={TOOLTIP.INFO.LEXT}>
                            <i className={`${TASK_ICONS.DISPLAY_INFO} px-2`}></i>
                          </PmivrTooltip>
                        </th>
                        <th width="10%" className="text-center">Transfer Call to CSR (code)
                          <PmivrTooltip message={TOOLTIP.INFO.TRANSFER_CODE}>
                            <i className={`${TASK_ICONS.DISPLAY_INFO} px-2`}></i>
                          </PmivrTooltip>
                        </th>
                        <th width="20%" className="text-center">Transfer Number
                          <PmivrTooltip message={TOOLTIP.INFO.TRANSFER_NUMBER}>
                            <i className={`${TASK_ICONS.DISPLAY_INFO} px-2`}></i>
                          </PmivrTooltip>
                        </th>
                        <th width="20%" className="text-center">Configure Values
                          <PmivrTooltip message={TOOLTIP.INFO.ADD_DNID_VALUES}>
                            <i className={`${TASK_ICONS.DISPLAY_INFO} px-2`}></i>
                          </PmivrTooltip>
                        </th>
                        <th width="10%" className="text-center">Actions</th>
                      </tr>
                    </thead>
                  </table>
                  <div className="pmivr-scroll">
                    {dnidRows?.map((row, index) => (
                      <ul key={index} className="align-items-end dnid-variable-heading">
                        <li className="dnid-variables" style={{ width: "20%" }}>

                          {uiState.clientFlowExists && index <= uiState.savedDnidLength - 1 ?
                            <input disabled={uiState?.formDisabled || (uiState?.clientFlowExists && index <= uiState.savedDnidLength - 1)}
                              className="dropdown p-2" type="text" value={row.dnid} />
                            :
                            <select className="dropdown" value={row.dnid}
                              onChange={(e) => { handleSelectDnid(e.target.value, index) }}
                              disabled={uiState.showWarning || uiState?.formDisabled}>
                              <option value={""}>Select</option>
                              {numberPool.map((numberInfo) => (
                                <option key={numberInfo.phoneNumber} value={numberInfo.phoneNumber}>
                                  {numberInfo.phoneNumber}
                                </option>
                              ))}
                            </select>
                          }

                        </li>
                        <li className="dnid-variables" style={{ width: "20%" }}>
                          {row?.lext ?
                            <p disabled={true}>{row.lext}</p> :
                            (row?.showExt) ?
                              <h6 className="error-msg">{MESSAGES.ERR.CONFIGURE_EXT}</h6>
                              : "-"
                          }
                        </li>
                        <li className="dnid-variables" style={{ width: "10%" }}>
                          {row?.transferChannelCode ?
                            <p disabled={true}>{row.transferChannelCode}</p> : "-"
                          }
                        </li>
                        <li className="dnid-variables" style={{ width: "20%" }}>
                          {row?.transferNumber ?
                            <p disabled={true}>{row.transferNumber}</p> : "-"
                          }
                        </li>
                        <li className="dnid-variables" style={{ width: "20%" }}>
                          <button className="pmivr-btn-secondary ms-2"
                            onClick={() => handleDialogOpen(row, index)}
                            disabled={uiState?.formDisabled}>Add / View DNID values</button>
                        </li>
                        <li style={{ width: "10%" }}>
                          <button type="button"
                            className="pmivr-btn-secondary"
                            onClick={() => handleRemoveDnid(index)}
                            title="Remove DNID"
                            disabled={(index > uiState.savedDnidLength - 1) ? false : true}>
                            <i className="bi bi-x-lg"></i>
                          </button>
                        </li>
                      </ul>
                    ))}
                  </div>
                </> : MESSAGES.ERR.NO_DNID}
            </div>

            <div className={uiState.clientFlowExists ? `${CSS_CLASSES.HIDE_DISPLAY}` : `${CSS_CLASSES.INLINE_DISPLAY}`}>
              <h5 className="pmivr-title  px-3 pt-3">Setup IVR to create new client</h5>
            </div>
            <div className={uiState.clientFlowExists ? `${CSS_CLASSES.INLINE_DISPLAY} d-flex` : `d-flex ${CSS_CLASSES.HIDE_DISPLAY}`}>
              <h5 className="pmivr-title   px-3 pt-3">Setup IVR for client</h5>
            </div>
            {uiState.clientFlowExists && !uiState.formDisabled &&
              <div class="alert alert-warning me-2 ms-3 mb-0 mt-1" role="alert">
                <i class="bi-exclamation-triangle-fill"></i> <strong>{MESSAGES.WIZARD_INFO}</strong>
              </div>
            }
            <div className={`${uiState.formDisabled ? "pmivr-disabled" : ""} p-3  float start`} id='wizard-questions'>
              <ul className="questions">{getQuestions(questionnaire)}</ul>
              <div className="row pb-3">
                {!uiState?.clientFlowExists && !uiState.formDisabled && (
                  <div className="col-sm-12 float-end">
                    <button className="pmivr-btn-app p-2 px-3  float-end  mt-3"
                      disabled={(uiState.showWarning) || isButtonDisabled || !dnidRows.length}
                      onClick={() => saveWizard(questionnaire)} id="wizard-submit">
                      Submit
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </PmivrAppLayout>
    </>
  );
};

export default Wizard;