import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ButtonGroup, Dropdown } from "react-bootstrap";

import PropTypes from 'prop-types';

import { MESSAGES, TOOLTIP } from "../../../../constants/messages";
import { FLOW_FLAGS, FLOW_STATUS, FLOW_UPLOAD_STATUS } from "../../../../constants/flow";

import AppUtil from "../../../../util/app.util";

import { updatePublished } from "../../../../redux/actions/client.action";

import FlowService from "../../../../services/flow.service";

import PmivrTooltip, { TOOLTIP_POSITIONS } from "../../../../components/common/tooltip/pmivr-tooltip";
import PmivrSnackBar from "../../../../components/common/dialog/pmivr-snackbar";
import UserService from "../../../../services/user.service";

/**
 * List of different published and draft versions of the flows
 * @param {Object} props props data from parent component
 * @returns {React.Component} Html code to render list.
 */
const DocVersionListItem = (props) => {
    const { navigateToFlow, doc, showHistoryDialogBox, flowType, isLatest = false, onDownloadFlowXml, onToggleRepublishDialog, onFlagStatusChange } = props;

    const dispatch = useDispatch();
    let { published } = useSelector(state => state.client);

    // using the open method from the snackbar component
    const snackbarRef = useRef();

    const [flowUploadStatus, setFlowUploadStatus] = useState(doc?.flowUploadStatus);

    // flag button labels to show on list of versions
    const FLAG_BUTTON_LABELS = {
        [FLOW_FLAGS.WORKING]: 'W',
        [FLOW_FLAGS.FAILED]: 'F'
    };

    useEffect(() => {
        // update the flow status on load
        setFlowUploadStatus(doc?.flowUploadStatus);
    }, [doc]);

    /**
     * renders a styled button intended for use in dropdown or submenu contexts. 
     */
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <button className="flag-submenu-btn dropdown-item ps-3 mb-1"
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
            <i className="bi bi-chevron-right sub-menu-toggle"></i>
        </button>
    ));

    /**
     * get the flow information to check the status of the flow that is in progress
     * @param {{businessCode,versionId,status}} doc 
     */
    const getFlowInfo = async (doc) => {
        const filters = { flowInfo: doc, status: doc?.status, count: 1, page: 1 };
        const flowInfo = await FlowService.getFlowInfo(filters);
        if (flowInfo.length) {
            setFlowUploadStatus(flowInfo[0]?.flowUploadStatus);
            const basicFlowInfo = FlowService.getBasicFlowInfo();
            basicFlowInfo.docVersionId = flowInfo[0]?.versionId;
            FlowService.setBasicFlowInfo(basicFlowInfo);

            //get the temp list of published versions
            const tmpPublishedList = [...published];
            const index = tmpPublishedList.findIndex(obj => obj._id === doc._id);
            //get the flow to update
            const flowToUpdate = { ...tmpPublishedList[index] };
            // set the verison id in the flow and update the list
            flowToUpdate["versionId"] = flowInfo[0]?.versionId;
            flowToUpdate["flowUploadStatus"] = flowInfo[0]?.flowUploadStatus;
            if (index !== -1) {
                tmpPublishedList[index] = flowToUpdate;
            }
            dispatch(updatePublished({ published: tmpPublishedList }));
        }
    }

    /**
     * Display document items, draft and published versions
     * @param {Object} doc
     * @returns {JSX.Element} - The JSX element representing one item of the doc
     */
    const renderDocItemContent = (doc) => {
        return (
            <div className="row py-1">
                <div className="col-sm-1 text-start mt-auto mb-auto" >
                    <PmivrTooltip message={[doc?.comment?.description || MESSAGES.NO_COMMENTS, AppUtil.formatDateInLocal(doc.updatedOn)]} position={TOOLTIP_POSITIONS.RIGHT}>
                        <div className="user-initials-circle">
                            <span>{UserService?.getUserInitials(doc?.updatedBy)}</span>
                        </div>
                    </PmivrTooltip>
                </div>
                <div className="col-sm-10 text-start doc-version-item">
                    <div className="d-inline-block text-truncate w-100">
                        <span className="text-bold" >Name: </span>
                        {doc?.comment?.name ? (
                            <PmivrTooltip message={doc?.comment?.name} position={TOOLTIP_POSITIONS.TOP}>
                                <span>{doc?.comment?.name}</span>
                            </PmivrTooltip>
                        ) : (
                            <span>{MESSAGES.NO_NAME}</span>
                        )}
                    </div>
                    <br />
                    <span>{doc.updatedBy}</span>
                </div>
                {doc?.flag && FLAG_BUTTON_LABELS[doc?.flag] &&
                    <div className="col-sm-1 working-flag-icon">
                        <PmivrTooltip message={TOOLTIP.FLOW_FLAG[(doc?.flag).toUpperCase()]} position={TOOLTIP_POSITIONS.AUTO} >
                            <button className="working-flow-flag">
                                {FLAG_BUTTON_LABELS[doc.flag]}
                            </button>
                        </PmivrTooltip>
                    </div>
                }
            </div>
        );
    }

    return (
        <>
            <PmivrSnackBar ref={snackbarRef} />
            <div className="row">
                <div className="col-sm-12 version-list">
                    {/* if no status or succesfull status then simply render the doc item 
                    and if on refresh we get successful status then show the doc item*/}
                    {![FLOW_UPLOAD_STATUS.INPROGRESS, FLOW_UPLOAD_STATUS.FAILED].includes(flowUploadStatus) &&
                        <div className="version-btn-align">
                            <button className={`version-btn ${!isLatest ? (doc?.flag === FLOW_FLAGS.WORKING ? "working-version-btn" : "old-version-btn") : "pmivr-btn-app "}`} type="submit"
                                disabled={flowUploadStatus === FLOW_UPLOAD_STATUS.INPROGRESS}
                                onClick={() => navigateToFlow(flowType, doc.versionId)}>
                                {renderDocItemContent(doc)}
                            </button>
                            <div className="latest-change-history">
                                <Dropdown as={ButtonGroup} autoClose>
                                    <Dropdown.Toggle variant="secondary" className={`bg-transparent pmivr-btn-icon pmivr-btn rounded-circle d-flex justify-content-center align-items-center ${doc?.flag === FLOW_FLAGS.WORKING ? "mt-1 " : "mb-1 "}`}>
                                        <i className="bi bi-three-dots-vertical"></i>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {!isLatest && flowType === FLOW_STATUS.PUBLISHED &&
                                            <Dropdown as={ButtonGroup} drop='start' className="w-100">
                                                <Dropdown.Toggle as={CustomToggle} className="pmivr-btn pmivr-btn-secondary">
                                                    <i className="bi bi-flag me-1"></i> Flags
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    {
                                                        doc?.flag === FLOW_FLAGS.WORKING ? (
                                                            <Dropdown.Item eventKey="1.1" onClick={() => onFlagStatusChange(doc, "", flowType)} >
                                                                Remove Working
                                                            </Dropdown.Item>
                                                        ) : (
                                                            <Dropdown.Item eventKey="1.1" onClick={() => onFlagStatusChange(doc, FLOW_FLAGS.WORKING, flowType)} >
                                                                Working
                                                            </Dropdown.Item>
                                                        )
                                                    }
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        }
                                        {!isLatest && flowType === FLOW_STATUS.PUBLISHED &&
                                            <Dropdown.Item eventKey="2" onClick={(e) => onToggleRepublishDialog(e, doc)}>
                                                <i className="bi bi-arrow-up-circle me-1"></i> Re-Publish
                                            </Dropdown.Item>}
                                        <Dropdown.Item eventKey="3" onClick={() => showHistoryDialogBox(doc?.changeHistory)}
                                            disabled={flowUploadStatus === FLOW_UPLOAD_STATUS.INPROGRESS}>
                                            <i className="bi bi-clock-history me-1"></i> History
                                        </Dropdown.Item>
                                        <Dropdown.Item eventKey="4" onClick={() => onDownloadFlowXml(doc)}>
                                            <i class="bi bi-download me-1"></i> Download
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>

                            </div>
                        </div>}
                    {/* In progress or failed status of the flows and if on refresh not success status then show refresh button*/}
                    {[FLOW_UPLOAD_STATUS.INPROGRESS, FLOW_UPLOAD_STATUS.FAILED].includes(flowUploadStatus) &&
                        <div className="row">
                            <div className={(flowUploadStatus === FLOW_UPLOAD_STATUS.INPROGRESS) ? "col-md-10" : "col-md-12"}>
                                <PmivrTooltip message={(flowUploadStatus === FLOW_UPLOAD_STATUS.INPROGRESS) ?
                                    TOOLTIP.PUBLISHED_FLOW_IN_PROGRESS : TOOLTIP.PUBLISHED_FLOW_FAILED}>
                                    <div className="version-btn-align">
                                        <button className={`${(flowUploadStatus === FLOW_UPLOAD_STATUS.INPROGRESS) ? "pmivr-btn-app" : "pmivr-btn-error"} version-btn flow-in-progress-btn pmivr-disabled`}>
                                            {(flowUploadStatus === FLOW_UPLOAD_STATUS.INPROGRESS) ? flowUploadStatus : renderDocItemContent(doc)}
                                        </button>
                                        {flowUploadStatus === FLOW_UPLOAD_STATUS.FAILED &&
                                            <div className="latest-change-history">
                                                <Dropdown as={ButtonGroup} autoClose>
                                                    <Dropdown.Toggle variant="secondary" className={`bg-transparent pmivr-btn pmivr-btn-icon mb-1 rounded-circle d-flex justify-content-center align-items-center`}>
                                                        <i className="bi bi-three-dots-vertical"></i>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item eventKey="1" onClick={() => showHistoryDialogBox(doc?.changeHistory)}>
                                                            <i className="bi bi-clock-history me-1"></i> History
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        }
                                    </div>
                                </PmivrTooltip>
                            </div>
                            {/* refresh button assigned to the in progress flow to check the status of flow */}
                            {flowUploadStatus === FLOW_UPLOAD_STATUS.INPROGRESS &&
                                <div className="col-md-2 refresh-btn">
                                    <PmivrTooltip message={TOOLTIP.REFRESH_PUBLISH_VERSION_STATUS}>
                                        <button className="pmivr-btn-app version-btn flow-in-progress-btn"
                                            onClick={() => getFlowInfo(doc)}>
                                            <i class="bi bi-arrow-clockwise refresh-icon"></i>
                                        </button>
                                    </PmivrTooltip>
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>
        </>
    );
}

// Types of props passed in the component
DocVersionListItem.propTypes = {
    // function to navigate to the flow page
    navigateToFlow: PropTypes.func,
    // {versionId, flowUploadStatus}
    doc: PropTypes.object,
    // Function that triggers the history dialog box
    showHistoryDialogBox: PropTypes.func,
    // type of the flow draft or published
    flowType: PropTypes.string,
    // for css purpose we need to check for isLatest
    isLatest: PropTypes.bool,
    // function to download xml file of the document
    onDownloadFlowXml: PropTypes.func,
    //  Function that toggles republish dialog
    onToggleRepublishDialog: PropTypes.func,
    // Function that handles the flag update of flow versions
    onFlagStatusChange: PropTypes.func
}

export default DocVersionListItem;