import { TASK_TYPES_WITHOUT_INFO_ICON } from "../../../config/config";
import { TASK_ATTRIBUTES } from "../../../constants/attributes";
import { TASK_ICONS } from "../../../constants/css-classes.js";
import { EL_TYPE, COMMON_ACTIONS } from "../../../constants/element.js";

import DiagramUtil from "../../../util/diagram.util.js";

import DiagramService from "../../../services/diagram.service";

// Title for different controls
const CONTEXT_ICON_NAMES = {
  VOICE_TASK: 'Add a voice task',
  OPTION_USER_TASK: 'Options Selection',
  USER_INPUT_TASK: 'User Input',
  SAY_DATA_TASK: 'Prompt',
  SERVICE_TASK: 'Service Call',
  HANGUP_TASK: 'Disconnect Call/Hangup',
  TRANSFER_CALL: 'Call Transfer',
  VOICE_RECORD_TASK: 'Record',
  SUB_PROCESS: "Sub Process",
  VOICE_RECORD_START: "Voice Record Start",
  VOICE_RECORD_STOP: "Voice Record Stop",
}

/**
 * The custom context pad, providing options to add various control types to the nodes in the diagram.
 */
export default class CustomContextPad {

  constructor(bpmnFactory, config, contextPad, create, elementFactory, injector, translate) {
    this.bpmnFactory = bpmnFactory;
    this.create = create;
    this.elementFactory = elementFactory;
    this.translate = translate;

    if (config.autoPlace !== false) {
      this.autoPlace = injector.get('autoPlace', false);
    }

    contextPad.registerProvider(this);
  }

  /**
   * Function is required by bpmn to add the items into the context pad. We can customize the context pad.
   * the objects provided by bpmn(create, bpmnFactory, elementFactory, etc) are available inside this function only, 
   * and these are required to create the shapes from context pad. So, the structure of function  can't be changed.
   * While dragging and creating shapes, event is triggered and function is being called to create the shapes.
   * On dragging and clicking context pad item,  an event is triggered to create the shape which is done by the 
   * function that is being returned. This is done as per required by bpmn. 
   * @param {Object} element Properties passed
   * @returns Returns a function that creates the shape when an event is triggered.
   */
  getContextPadEntries(element) {
    const { autoPlace, bpmnFactory, create, elementFactory, translate } = this;
    const actions = {};

    // Check if the element is disabled
    const isElementDisabled = DiagramUtil.checkElementDisabled(element);

    function _createTask(taskType, attributes, event) {
      // create the shape
      const shape = DiagramService.createShape(bpmnFactory, elementFactory, attributes, taskType);
      // calulcates the x,y of shape
      create.start(event, shape);
      // of false user will manually has to place the element
      if (autoPlace) {
        autoPlace.append(element, shape);
      }
    }

    /**
     * 
     * @param {Object} attributes 
     * @param {string} elType 
     * @returns {Function} A function that initializes the task with the provided attributes and element type.
     */
    function createTaskInitializer(attributes, elType) {
      return function (event) {
        _createTask(elType, attributes, event);
      }
    }

    function displayInfo(suitabilityScore) {
      return function (event) {
        DiagramService.openRigthPanel();
      };
    }

    /**
     * Export the component as re usable component dialog triggering
     * @returns {Function} a function that sets the value of show re usable component dialog to true
     */
    function exportReUsableComponent() {
      return function (event) {
        DiagramService.triggerReUsableDialog(element);
      }
    }

    /**
     * Disable a task from the flow
     * @returns {Function} a function that sets the disable field to true for element
     */
    function disableTask() {
      return function (event) {
        DiagramService.triggerConfirmationDialog(element, COMMON_ACTIONS.DISABLE_TASK);
      }
    }

    /**
     * Enable a task from the flow
     * @returns {Function} a function that sets the disable field to false for element
     */
    function enableTask() {
      return function (event) {
        DiagramService.triggerConfirmationDialog(element, COMMON_ACTIONS.ENABLE_TASK);
      }
    }

    const generateTaskObj = (icon, title, attributes, elType) => ({
      icon,
      title: translate(title),
      action: {
        click: createTaskInitializer(attributes, elType),
        dragstart: createTaskInitializer(attributes, elType)
      }
    });

    const DISPLAY_INFO = {
      group: "model",
      className: TASK_ICONS.DISPLAY_INFO,
      title: translate("Display task info"),
      action: {
        click: displayInfo()
      }
    }

    const RE_USABLE_COMPONENT = {
      group: "model",
      title: translate("Export As Re-Usable"),
      className: TASK_ICONS.RE_USABLE_COMPONENT,
      action: {
        click: exportReUsableComponent()
      }
    }

    const DISABLE_TASK = {
      group: "model",
      title: translate("Disable Task"),
      className: TASK_ICONS.DISABLE_TASK,
      action: {
        click: disableTask()
      }
    }

    const ENABLE_TASK = {
      group: "model",
      title: translate("Enable Task"),
      className: TASK_ICONS.ENABLE_TASK,
      action: {
        click: enableTask()
      }
    }

    const TASK_TYPES = {
      'create-play-voice-file-task': generateTaskObj(TASK_ICONS.VOICE_TASK, CONTEXT_ICON_NAMES.VOICE_TASK,
        TASK_ATTRIBUTES.VOICE_TASK, EL_TYPE.TASK),
      'create-option-user-task': generateTaskObj(TASK_ICONS.OPTION_USER_TASK, CONTEXT_ICON_NAMES.OPTION_USER_TASK,
        TASK_ATTRIBUTES.OPTION_USER_TASK, EL_TYPE.USER_TASK),
      'create-user-input-task': generateTaskObj(TASK_ICONS.USER_INPUT_TASK, CONTEXT_ICON_NAMES.USER_INPUT_TASK,
        TASK_ATTRIBUTES.USER_INPUT_TASK, EL_TYPE.USER_TASK),
      'create-say-data-task': generateTaskObj(TASK_ICONS.SAY_DATA_TASK, CONTEXT_ICON_NAMES.SAY_DATA_TASK,
        TASK_ATTRIBUTES.SAY_DATA_TASK, EL_TYPE.TASK),
      'create-hangup-task': generateTaskObj(TASK_ICONS.HANGUP_TASK, CONTEXT_ICON_NAMES.HANGUP_TASK,
        TASK_ATTRIBUTES.HANGUP_TASK, EL_TYPE.TASK),
      'transfer-call': generateTaskObj(TASK_ICONS.TRANSFER_CALL, CONTEXT_ICON_NAMES.TRANSFER_CALL,
        TASK_ATTRIBUTES.TRANSFER_CALL_TASK, EL_TYPE.TASK),
      'create-voice-record-task': generateTaskObj(TASK_ICONS.VOICE_RECORD_TASK, CONTEXT_ICON_NAMES.VOICE_RECORD_TASK,
        TASK_ATTRIBUTES.VOICE_RECORD_TASK, EL_TYPE.USER_TASK),
      'create-service-impl-task': generateTaskObj(TASK_ICONS.SERVICE_TASK, CONTEXT_ICON_NAMES.SERVICE_TASK,
        TASK_ATTRIBUTES.SERVICE_TASK, EL_TYPE.SERVICE_TASK),
      'display-info': DISPLAY_INFO,
      'create.subprocess-expanded': generateTaskObj(TASK_ICONS.SUB_PROCESS, CONTEXT_ICON_NAMES.SUB_PROCESS,
        TASK_ATTRIBUTES.SUB_PROCESS, EL_TYPE.SUB_PROCESS),
      'create-voice-record-start-task': generateTaskObj(TASK_ICONS.VOICE_RECORD_START, CONTEXT_ICON_NAMES.VOICE_RECORD_START,
        TASK_ATTRIBUTES.VOICE_RECORD_START_TASK, EL_TYPE.TASK),
      'create-voice-record-stop-task': generateTaskObj(TASK_ICONS.VOICE_RECORD_STOP, CONTEXT_ICON_NAMES.VOICE_RECORD_STOP,
        TASK_ATTRIBUTES.VOICE_RECORD_STOP_TASK, EL_TYPE.TASK),
      // if the element is disabled then show the enable task option, otherwise disable task option
      [isElementDisabled ? 'enable-task' : 'disable-task']: isElementDisabled ? ENABLE_TASK : DISABLE_TASK,
      're-usable-component': RE_USABLE_COMPONENT
    }

    // append every element into the actions array for custom context pad
    Object.entries(TASK_TYPES).forEach(([key, value]) => {
      // Checking if the element if not a sequence flow and display info
      // because display info has only click action (not dragstart)
      if (element && element.type !== EL_TYPE.SEQUENCE_FLOW && !(Object.values(COMMON_ACTIONS)).includes(key)) {
        actions[`append.${key}`] = {
          group: 'model',
          className: value.icon,
          title: value.title,
          action: value.action
        }
      } else {
        // Adding info, disable/enable action onto the custom pad after filtering the element types
        if ((!TASK_TYPES_WITHOUT_INFO_ICON.includes(element?.type) ||
          [COMMON_ACTIONS.DISABLE_TASK, COMMON_ACTIONS.ENABLE_TASK].includes(key)) &&
          element?.type !== EL_TYPE.SEQUENCE_FLOW && ![COMMON_ACTIONS.RE_USABLE_COMPONENT].includes(key)) {
          actions[`append.${key}`] = value;
          // re usable icons should be on sub process and othe relements except sequence flow , since it is not re used
          // because sequence flow line is simply a flow line
        } else if (element && ![EL_TYPE.END_EVENT, EL_TYPE.START_EVENT, EL_TYPE.SEQUENCE_FLOW, EL_TYPE.LABEL, EL_TYPE.INTERMEDIATE_THROW_EVENT_TASK].includes(element.type) && [COMMON_ACTIONS.RE_USABLE_COMPONENT].includes(key)) {
          actions[`append.${key}`] = value;
        }
      }
    });

    return actions;
  }
}

CustomContextPad.$inject = ['bpmnFactory', 'config', 'contextPad', 'create', 'elementFactory', 'injector', 'translate'];