import { useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Typeahead } from "react-bootstrap-typeahead";
import { Accordion } from "react-bootstrap";

import { MESSAGES, TOOLTIP } from "../../../constants/messages";
import { ATTRIBUTES } from "../../../constants/attributes";
import { VOICE_FILE_UPLOAD_TYPE } from "../../../constants/voice-file";
import { TAB_LIST } from "../../../constants/element";

import { PmivrDialog } from "../../common/dialog/pmivr-dialog";
import PmivrOverlayTrigger from "../../common/overlay-trigger/pmivr-overlay-trigger";
import PmivrLabel from "../../common/label/pmivr-label";
import PmivrTooltip from "../../common/tooltip/pmivr-tooltip";
import { TASK_ICONS } from "../../../constants/css-classes";
import { PmivrCheckbox } from "../../common/checkbox/pmivr-checkbox";

import { DEFAULT_RETRY_COUNT, DEFAULT_USER_INPUT_INVALID_VOICE_FILE } from "../../../config/config";
import { VoiceContext } from "../../../contexts/app-context";

import AudioUtil from "../../../util/audio.util";
import StringUtil from "../../../util/string.util";
import UserInputUtil from "../../../util/user-input.util";
import AppUtil from "../../../util/app.util";

import VoicePropsView from "../voice-file-properties/VoicePropsView";
import UserInputProperties from "./UserInputPropertiesModal";
import SpeechInput from "../speech-input/SpeechInput";
import ReadbackInputData from "../readback-input-data/readback-input-data";

import ElementService from "../../../services/element.service";
import AudioService from "../../../services/audio.service";
import DiagramService from "../../../services/diagram.service";
import VariableService from "../../../services/variable.service";

/**
 * User input properties view in diagram shown as left panel
 * @param {Object} props Props data from parent component
 */
const UserInputPropertiesView = () => {
  // maintain the original state of voice file info to update when user press the cancel button 
  const [originalVoiceFileInfo, setOriginalVoiceFileInfo] = useState({});
  const [userInputInvalidInputVoiceFileUploadType, setUserInputInvalidInputVoiceFileUploadType] = useState("");
  // options menu to render or not
  const [showOptionMenu, setShowOptionMenu] = useState(false);
  // saves invalid voice file path
  const [invalidVoiceFilePath, setInvalidVoiceFilePath] = useState("");
  // language selected
  const [selectedLanguage, setSelectedLanguage] = useState("");
  // information for invalid voice file 
  const [invalidVoiceFileInfo, setInvalidVoiceFileInfo] = useState({});
  // array of supported languages
  const [supportedLanguages, setSupportedLanguages] = useState([]);
  // since we have empty voiceFileInfo in the element
  // so create tmp voice file info to keep the track of selected voice file methods and paths
  const [tmpVoiceFileInfo, setTmpVoiceFileInfo] = useState({});
  const [tmpVoiceFileType, setTmpVoiceFileType] = useState({});
  // ui checkboxes state props
  const [uiCheckboxesState, setUiCheckboxesState] = useState({
    requirePrefixSymbolUserInput: false, requireSeparatorUserInput: false,
    requirePatternsUserInput: false, userInputReadBack: false, isRegexValidation: false
  });
  // key of the currently active accordion item
  const [activeAccordionKey, setActiveAccordionKey] = useState(null);
  // state of variables with their info
  const [variableInfo, setVariableInfo] = useState({
    variables: [], variablesName: [], minValueSelectedVariable: '', minValueSelectedVariableDesc: '', maxValueSelectedVariable: '',
    maxValueSelectedVariableDesc: ''
  });

  const ttsRef = useRef();
  const uploadRef = useRef();

  const { rightPanelEventHandler, element, tabType } = useContext(VoiceContext);

  // latest state from redux store
  const { voiceFilePrefixObj } = useSelector(state => state.voiceFile);
  /**
   * errMsg : msg to display incase of any invalid input
   * activeTab : to maintain state of of tab opened on new element
   */
  const [uiState, setUiState] = useState({
    errMsg: "", activeTab: "custom-audio",
    validationInput: ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_REGEX_VALIDATION)
  });
  // ref for openeing by default custom-audio tab
  const createAudioTabRef = useRef(null);

  /**
   * value type for the min value and max value
   * { text: "Variable", value: "variable" }: User can define dynamic value for the min / max value by using 
   *                                          any variable specifying the value.
   * { text: "Value", value: "value" }: User can define some constant value for min / max value
   */
  const VALUE_TYPE_OPTIONS = [
    { text: "Variable", value: "variable" },
    { text: "Value", value: "value" }
  ]

  useEffect(() => {
    // if the user has allowed to accept input during readback of user-input
    const allowInputDuringReadback = element.businessObject.get(ATTRIBUTES.ALLOW_INPUT_DURING_READBACK);
    if (!AppUtil.isValueValid(allowInputDuringReadback)) {
      // if not defined, then set it to true
      ElementService.updateElementAttr(element, ATTRIBUTES.ALLOW_INPUT_DURING_READBACK, true);
    }
    // whether or not to end call after retries
    const endCallOnInvalidInputAfterRetries = element.businessObject.get(ATTRIBUTES.END_CALL_ON_INVALID_USER_INPUT_AFTER_RETRIES);
    if (!AppUtil.isValueValid(endCallOnInvalidInputAfterRetries)) {
      // if not defined, then set it to true
      ElementService.updateElementAttr(element, ATTRIBUTES.END_CALL_ON_INVALID_USER_INPUT_AFTER_RETRIES, true);
    }
    // Whenever `element` changes, reset the active tab to 'custom-audio'
    setUiState({
      ...uiState, activeTab: "custom-audio",
      validationInput: ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_REGEX_VALIDATION)
    })
    // when open second element then it is not updating the tab , need to update the tab content also create-audio or user-input
    if (createAudioTabRef?.current) {
      createAudioTabRef.current.click();
    }
    // update the checkboxes on element changes
    setCheckboxesPropsInState({
      requirePrefixSymbolUserInput: ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_REQUIRE_PREFIX, false),
      requireSeparatorUserInput: ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_REQUIRE_SEPARATOR, false),
      requirePatternsUserInput: ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_REQUIRE_PATTERNS, false),
      userInputReadBack: ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_OPTION_READBACK, false),
      isRegexValidation: ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_IS_REGEX_VALIDATION, false),
    });

    // populate the state with the variables list
    populateVariables();
  }, [element]);

  useEffect(() => {
    const init = async () => {
      const invalidVoiceFile = await AudioService.getVoiceFileInfo(element,
        ATTRIBUTES.USER_INPUT_OPTION_INVALID_OPTION_FILE, selectedLanguage);
      // info of the only selected language voice file to display on UI
      const formattedVoiceFileInfo = AudioUtil.getFormattedVoiceFileInfo(invalidVoiceFile.selectedLanguage,
        invalidVoiceFile.voiceFileInformation);
      // update the attribute and default invalid voice file for a new user input component.
      setDefaultInvalidVoiceFile(invalidVoiceFile);
      setUserInputInvalidInputVoiceFileUploadType(VOICE_FILE_UPLOAD_TYPE.LIBRARY);
      setShowOptionMenu(false);
      setInvalidVoiceFilePath(formattedVoiceFileInfo[invalidVoiceFile.selectedLanguage]?.filePath ||
        (voiceFilePrefixObj[invalidVoiceFile.selectedLanguage] + DEFAULT_USER_INPUT_INVALID_VOICE_FILE));
      // save the selected language
      setSelectedLanguage(invalidVoiceFile.selectedLanguage);
      // save the voice file info
      setInvalidVoiceFileInfo(formattedVoiceFileInfo);
      // save the supported languages
      setSupportedLanguages(invalidVoiceFile.supportedLanguages);
      // initially se the upload mehtod 
      setUserInputInvalidInputVoiceFileUploadType(formattedVoiceFileInfo[invalidVoiceFile.selectedLanguage].voiceFileType);

      const retryCount = ElementService.getAttribute(element,
        ATTRIBUTES.USER_INPUT_OPTION_RETRY_COUNT) || DEFAULT_RETRY_COUNT;
      ElementService.updateElement(element, ATTRIBUTES.USER_INPUT_OPTION_RETRY_COUNT, retryCount);
      // set the temp information of voice file for initial render of filePaths
      setTempVoiceFileInfo(invalidVoiceFile);
      const endCallOnInvalidInputAfterRetries = element.businessObject.get(ATTRIBUTES.END_CALL_ON_INVALID_USER_INPUT_AFTER_RETRIES);
      if (!AppUtil.isValueValid(endCallOnInvalidInputAfterRetries)) {
        // if not defined, then set it to true
        ElementService.updateElementAttr(element, ATTRIBUTES.END_CALL_ON_INVALID_USER_INPUT_AFTER_RETRIES, true);
      }
      // get the original voice file info list and set it
      getOriginalVoiceFileInfo();
    }
    init();
  }, []);

  useEffect(() => {
    // update the voice file method selected to know while saving which method was selected
    // to get saved in voiceFileInfo 
    if (selectedLanguage) {
      if (Object.keys(tmpVoiceFileType).length) {
        tmpVoiceFileType[selectedLanguage].fileType = userInputInvalidInputVoiceFileUploadType;
        setTmpVoiceFileType(tmpVoiceFileType);
      }
    }
  }, [userInputInvalidInputVoiceFileUploadType]);

  /**
   * Get the original voice file info
   */
  const getOriginalVoiceFileInfo = async () => {
    const invalidVoiceFile = await AudioService.getVoiceFileInfo(element,
      ATTRIBUTES.USER_INPUT_OPTION_INVALID_OPTION_FILE, selectedLanguage);
    // info of the only selected language voice file to display on UI
    const formattedVoiceFileInfo = AudioUtil.getFormattedVoiceFileInfo(invalidVoiceFile.selectedLanguage,
      invalidVoiceFile.voiceFileInformation);
    setOriginalVoiceFileInfo(formattedVoiceFileInfo);
  }

  /**
   * Set the temp voice file info
   * @param {{supportedLanguages, selectedLanguage, voiceFileInformation}} invalidVoiceFile
   */
  const setTempVoiceFileInfo = (invalidVoiceFile) => {
    const tempVoiceInfo = {};
    const tempVoiceFileType = {};
    // initially set the temp file info for the respective languages and set temp voice file info
    invalidVoiceFile?.supportedLanguages.forEach((lang) => {
      const tmpVoiceFileInfo = AudioUtil.getFormattedVoiceFileInfo(lang, null);
      tempVoiceInfo[lang] = tmpVoiceFileInfo;
      tempVoiceFileType[lang] = { "fileType": VOICE_FILE_UPLOAD_TYPE.LIBRARY };
    });
    setTmpVoiceFileInfo(tempVoiceInfo);
    setTmpVoiceFileType(tempVoiceFileType);
  }

  // Get variables and set it in variables state.
  const populateVariables = async () => {
    const variablesInfo = await VariableService.getVariables();
    // names of all the variables
    const allVariablesNames = variablesInfo.map((varInfo) => varInfo.name);

    // getting minimum value variable
    const minValueVariable = ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_MIN_VALUE, '');
    // getting minimum value variable details
    const minValueSelectedVariableDetails = getVariableDetails(variablesInfo, minValueVariable);
    // getting maximum value variable
    const maxValueVariable = ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_MAX_VALUE, '');
    // getting maximum value variable details
    const maxValueSelectedVariableDetails = getVariableDetails(variablesInfo, maxValueVariable);
    // populating the variables in state
    setVariableInfo({
      ...variableInfo, variables: variablesInfo, variablesName: allVariablesNames,
      minValueSelectedVariable: minValueVariable, maxValueSelectedVariable: maxValueVariable,
      minValueSelectedVariableDesc: minValueSelectedVariableDetails?.description,
      maxValueSelectedVariableDesc: maxValueSelectedVariableDetails?.description
    });
  }

  /**
   * Finds the details for the given variable from variable list
   * @param {array} variables variable list
   * @param {string} variableName variable name whose description is to be find
   * @returns {string} details for given variable 
   */
  const getVariableDetails = (variables = [], variableName = '') => {
    if (!variableName) return '';
    return variables?.find(variable => variable.name === variableName) || '';
  };

  /**
   * Sets the default invalid voice file for a new user input component and updates attribute in flow xml
   * @param {Object} invalidVoiceFile - details of the invalid voice file for component 
   */
  const setDefaultInvalidVoiceFile = async (invalidVoiceFile) => {
    // supported languages for the flow
    const supportedLanguages = await DiagramService.getSupportedLanguages();
    let defaultInvalidVoiceFileInfo = {};
    if (supportedLanguages?.length) {
      // append the filePath for each supported language into the default voice file info, for a new component
      supportedLanguages.forEach((language) => {
        const invalidVoiceFileFormattedInfo = AudioUtil.getFormattedVoiceFileInfo(language, invalidVoiceFile.voiceFileInformation);
        // if the component is new, it will not have filePath for default voice file
        if (!invalidVoiceFileFormattedInfo[language]?.filePath) {
          invalidVoiceFileFormattedInfo[language].filePath =
            (voiceFilePrefixObj[language] + DEFAULT_USER_INPUT_INVALID_VOICE_FILE);
        }
        defaultInvalidVoiceFileInfo[language] = invalidVoiceFileFormattedInfo[language];
      });
      // update the attribute in xml
      ElementService.updateElementAttr(element, ATTRIBUTES.USER_INPUT_OPTION_INVALID_OPTION_FILE,
        JSON.stringify(defaultInvalidVoiceFileInfo));
    }
  }

  /**
   * Updating the checkboxes state props in the state
   * @param {{ requirePrefixSymbolUserInput, requireSeparatorUserInput, 
   * requirePatternsUserInput, userInputReadBack,isRegexValidation }} uiStateProps ui state props
   */
  const setCheckboxesPropsInState = (uiStateProps = {
    requirePrefixSymbolUserInput: false,
    requireSeparatorUserInput: false, requirePatternsUserInput: false, userInputReadBack: false,
    isRegexValidation: false
  }) => {
    setUiCheckboxesState((prevState) => {
      const newState = { ...prevState };
      newState.requirePrefixSymbolUserInput = StringUtil.toBoolean(uiStateProps.requirePrefixSymbolUserInput);
      newState.requireSeparatorUserInput = StringUtil.toBoolean(uiStateProps.requireSeparatorUserInput);
      newState.requirePatternsUserInput = StringUtil.toBoolean(uiStateProps.requirePatternsUserInput);
      newState.userInputReadBack = StringUtil.toBoolean(uiStateProps.userInputReadBack);
      newState.isRegexValidation = StringUtil.toBoolean(uiStateProps.isRegexValidation);
      return newState;
    })

  }

  /**
     * Handles the selection of a variable from the Typeahead component. Updates the flow xml element with min and max values.
     * Only for selecting variable for min and max value for user input.
     * @param {Object} selected The selected option from the Typeahead component.
     * @param {string} field the field whose value is being changed (minValue / maxValue)
     */
  const handleVariableSelection = (selected, field = "") => {
    const selectedVariable = selected.length > 0 ? selected[0] : "";
    updateVariableInfo(selectedVariable, field);
  }

  /**
    * Updates the variable selected for min value and max value. Updates the flow xml element with min and max values.
    * @param {string} text The current text input in the Typeahead component
    * @param {Object} field  the field whose value is being changed (minValue / maxValue)
    */
  const updateVariableInfo = (text, field = "") => {
    const selectedVariable = text;
    const selectedVariableDetails = getVariableDetails(variableInfo.variables, selectedVariable);
    if (field === 'minValue') {
      // change is for the minimum value input (variable type)
      setVariableInfo({
        ...variableInfo, minValueSelectedVariable: selectedVariable,
        minValueSelectedVariableDesc: selectedVariableDetails?.description
      });
      ElementService.updateElement(element, ATTRIBUTES.USER_INPUT_MIN_VALUE, selectedVariable);
    } else {
      // change is for the maximum value input (variable type)
      setVariableInfo({
        ...variableInfo, maxValueSelectedVariable: selectedVariable,
        maxValueSelectedVariableDesc: selectedVariableDetails?.description
      });
      ElementService.updateElement(element, ATTRIBUTES.USER_INPUT_MAX_VALUE, selectedVariable);
    }
  }

  // reset the state of this component and it's child components
  const resetStates = () => {
    // get the voice file info attribute from element
    let invalidVoiceFileInfo = ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_OPTION_INVALID_OPTION_FILE);
    if (invalidVoiceFileInfo) {
      invalidVoiceFileInfo = JSON.parse(invalidVoiceFileInfo);
      // get the temporary data for the attribute
      const voiceFileInfoTemplate = AudioUtil.getFormattedVoiceFileInfo(selectedLanguage, invalidVoiceFileInfo);
      // set the fileds necessary for the attribute
      setInvalidVoiceFileInfo(voiceFileInfoTemplate);
      setUserInputInvalidInputVoiceFileUploadType(voiceFileInfoTemplate[selectedLanguage].voiceFileType);
    }
    // need to clear the values from state for resetting in case of tts and upload file after updation in xml file
    if (userInputInvalidInputVoiceFileUploadType !== VOICE_FILE_UPLOAD_TYPE.LIBRARY) {
      setInvalidVoiceFilePath(voiceFilePrefixObj[selectedLanguage] + DEFAULT_USER_INPUT_INVALID_VOICE_FILE);
    }
    ttsRef.current.resetTtsOption(); // reset the voice file info states of TTS component so that changes reflect on UI
    uploadRef.current.resetUploadOption();  // reset the voice file info states of UPLOAD component so that changes reflect on UI
  }

  /**
   * Show the save voice file options and setting in right panel
   * @param {string} lang language configured
   * @returns {string} file path of the invalid voice file info 
   */
  const getInvaildVoiceFileInfo = (lang) => {
    let invalidVoiceFileInfo = ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_OPTION_INVALID_OPTION_FILE);
    invalidVoiceFileInfo = JSON.parse(invalidVoiceFileInfo);
    if (Object.keys(invalidVoiceFileInfo).length) {
      return invalidVoiceFileInfo[lang]?.filePath;
    }
    return "Enter Invalid Voice File";
  }

  // update voice file state whenever user selects different langauge option to display the information of that language file.
  const updateVoiceFileStateInfo = async (language) => {
    const languageSelected = language ? language : "en";
    const userInputInvalidVoiceFileUploadType = invalidVoiceFileInfo[language]?.voiceFileType || VOICE_FILE_UPLOAD_TYPE.LIBRARY;
    const invalidVoiceFilePathNew = tmpVoiceFileInfo[language]?.filePath || invalidVoiceFileInfo[language]?.filePath;
    const { voiceFileInformation } = await AudioService.getVoiceFileInfo(element, ATTRIBUTES.USER_INPUT_OPTION_INVALID_OPTION_FILE, languageSelected);

    // set the invalidVoiceFileInfo of the latest language selected
    setInvalidVoiceFileInfo(voiceFileInformation);
    // get the temp voice fle info and set the values on language change in tab values
    const formatedInvalidVoiceFileInfo = AudioUtil.getFormattedVoiceFileInfo(languageSelected, voiceFileInformation);
    formatedInvalidVoiceFileInfo[languageSelected].voiceFileType = userInputInvalidInputVoiceFileUploadType;
    formatedInvalidVoiceFileInfo[languageSelected].filePath = invalidVoiceFilePath;

    // set the selected language
    setSelectedLanguage(languageSelected);
    // set the upload method of voice file tts, library or upload
    setUserInputInvalidInputVoiceFileUploadType(userInputInvalidVoiceFileUploadType);
    setInvalidVoiceFilePath(invalidVoiceFilePathNew || voiceFilePrefixObj[language] + DEFAULT_USER_INPUT_INVALID_VOICE_FILE);
    setInvalidVoiceFileInfo(formatedInvalidVoiceFileInfo);
  }

  // set varibale's value before option the pop up
  const handleShowOptionMenu = () => {
    setShowOptionMenu(true);
  }

  // set variable's value when user close the pop up
  const handleCloseOptionMenu = () => {
    ElementService.updateElement(element, ATTRIBUTES.VOICE_TEXT, "");
    let invalidVoiceFileUploadType = ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_OPTION_INVALID_OPTION_FILE_TYPE);
    setShowOptionMenu(false);
    setUserInputInvalidInputVoiceFileUploadType(invalidVoiceFileUploadType || VOICE_FILE_UPLOAD_TYPE.LIBRARY);
    setInvalidVoiceFileInfo(originalVoiceFileInfo);
    ElementService.updateElementAttr(element, ATTRIBUTES.USER_INPUT_OPTION_INVALID_OPTION_FILE, JSON.stringify(originalVoiceFileInfo));
  }

  // save the changes when save changes clicked
  const handleSaveOptionMenu = async () => {
    // create a copy of tmpVoiceFileType since we append it for updating the state
    const originalTmpVoiceFileType = { ...tmpVoiceFileType };
    if (userInputInvalidInputVoiceFileUploadType === VOICE_FILE_UPLOAD_TYPE.LIBRARY) {
      handleSaveLibraryFile();
    } else {
      // if not library method then get the info
      let invalidVoiceFileInfo = ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_OPTION_INVALID_OPTION_FILE);
      invalidVoiceFileInfo = JSON.parse(invalidVoiceFileInfo);

      delete tmpVoiceFileType[selectedLanguage];
      //get the info of all languages except the current selected language
      const remainingLangFileTypes = Object.keys(tmpVoiceFileType);
      remainingLangFileTypes?.length && remainingLangFileTypes.forEach((lang) => {
        const voiceFileInfoTemplate = {};
        let obj = AudioUtil.getFormattedVoiceFileInfo(lang, null);
        voiceFileInfoTemplate[lang] = obj;
        // set the fields for all the languages for voice file info
        if (tmpVoiceFileType[lang]?.fileType === VOICE_FILE_UPLOAD_TYPE.LIBRARY) {
          voiceFileInfoTemplate[lang]["filePath"] = tmpVoiceFileInfo[lang]?.filePath || invalidVoiceFileInfo[lang]?.filePath;
          voiceFileInfoTemplate[lang]["voiceFileType"] = VOICE_FILE_UPLOAD_TYPE.LIBRARY;
          voiceFileInfoTemplate[lang]["ttsText"] = "";
          invalidVoiceFileInfo[lang] = voiceFileInfoTemplate[lang];
        }
      });
      //update the attribute
      ElementService.updateElementAttr(element, ATTRIBUTES.USER_INPUT_OPTION_INVALID_OPTION_FILE, JSON.stringify(invalidVoiceFileInfo));
    }
    setShowOptionMenu(false);
    setTmpVoiceFileType(originalTmpVoiceFileType);
    // set the original file info now as the saved info
    let invalidVoiceFileInfo = ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_OPTION_INVALID_OPTION_FILE);
    invalidVoiceFileInfo = JSON.parse(invalidVoiceFileInfo);
    setOriginalVoiceFileInfo(invalidVoiceFileInfo);
  }

  /**
   * Saves the voice file info if voice file method is libraray
   */
  const handleSaveLibraryFile = () => {
    // get the voice file info attribute from element
    let invalidVoiceFileInfo = ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_OPTION_INVALID_OPTION_FILE);
    if (invalidVoiceFileInfo) {
      invalidVoiceFileInfo = JSON.parse(invalidVoiceFileInfo);
      // get the temporary data for the attribute
      const voiceFileInfoTemplate = AudioUtil.getFormattedVoiceFileInfo(selectedLanguage, invalidVoiceFileInfo);
      // set the fileds necessary for the attribute
      voiceFileInfoTemplate[selectedLanguage].filePath = invalidVoiceFilePath;
      voiceFileInfoTemplate[selectedLanguage].voiceFileType = VOICE_FILE_UPLOAD_TYPE.LIBRARY;
      voiceFileInfoTemplate[selectedLanguage].ttsText = "";

      delete tmpVoiceFileType[selectedLanguage];
      const remainingLangFileTypes = Object.keys(tmpVoiceFileType);
      // get the remaining languages info and check for library file and set its path and then save the attribute 
      remainingLangFileTypes?.length && remainingLangFileTypes.forEach((lang) => {
        if (tmpVoiceFileType[lang].fileType === VOICE_FILE_UPLOAD_TYPE.LIBRARY) {
          voiceFileInfoTemplate[lang].filePath = tmpVoiceFileInfo[lang].filePath || invalidVoiceFileInfo[lang].filePath;
          voiceFileInfoTemplate[lang].voiceFileType = VOICE_FILE_UPLOAD_TYPE.LIBRARY;
          voiceFileInfoTemplate[lang].ttsText = "";
        }
      });
      // updating invalid voice file state
      setInvalidVoiceFileInfo(voiceFileInfoTemplate);
      // finally update the attribute on save
      ElementService.updateElementAttr(element, ATTRIBUTES.USER_INPUT_OPTION_INVALID_OPTION_FILE, JSON.stringify(voiceFileInfoTemplate));
    } else {
      ElementService.updateElementAttr(element, ATTRIBUTES.USER_INPUT_OPTION_INVALID_OPTION_FILE, JSON.stringify(tmpVoiceFileInfo));
    }
  }

  /**
  * Update the voiceFileUploadMethod state
  * @param {string} val Value of voice file type
  */
  const handleVoiceFileUploadMethod = (val) => {
    // get the voice file info attribute from element
    let invalidVoiceFileInfo = ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_OPTION_INVALID_OPTION_FILE);
    if (invalidVoiceFileInfo) {
      invalidVoiceFileInfo = JSON.parse(invalidVoiceFileInfo);
      // get the temporary data for the attribute
      const voiceFileInfoTemplate = AudioUtil.getFormattedVoiceFileInfo(selectedLanguage, invalidVoiceFileInfo);
      // set the fileds necessary for the attribute
      setInvalidVoiceFilePath(voiceFileInfoTemplate[selectedLanguage]?.filePath);
    }
    setUserInputInvalidInputVoiceFileUploadType(UserInputUtil.getConfiguredVoiceFileUploadMethod(val));
  }

  /**
  * Update the invaildVoiceFilePath state
  * @param {string} value Value of invalidVoice file path
  */
  const updateVoiceFilePathState = (value) => {
    setInvalidVoiceFilePath(value);
    tmpVoiceFileInfo[selectedLanguage].filePath = value;
    tmpVoiceFileInfo[selectedLanguage].voiceFileType = VOICE_FILE_UPLOAD_TYPE.LIBRARY;
    setTmpVoiceFileInfo(tmpVoiceFileInfo);
  }

  /**
   * Update the element based on attribute value
   * @param {Object} element 
   * @param {Object} event Object which contains the value of checkbox 
   * @param {string} attribute Attribute to update its value
   */
  const updateUserInputAttribute = (element, event, attribute) => {
    const isChecked = event.target.checked;
    ElementService.updateElement(element, attribute, isChecked);
    switch (attribute) {
      case ATTRIBUTES.USER_INPUT_IS_REGEX_VALIDATION:
        if (!isChecked) {
          setUiState({ ...uiState, validationInput: "", errMsg: "" });
          ElementService.updateElement(element, ATTRIBUTES.USER_INPUT_REGEX_VALIDATION, "");
          ElementService.updateElement(element, ATTRIBUTES.USER_INPUT_MIN_DIGITS, "");
          ElementService.updateElement(element, ATTRIBUTES.USER_INPUT_MAX_DIGITS, "");
        }
        setCheckboxesPropsInState({
          requirePrefixSymbolUserInput: uiCheckboxesState.requirePrefixSymbolUserInput,
          requireSeparatorUserInput: uiCheckboxesState.requireSeparatorUserInput,
          requirePatternsUserInput: uiCheckboxesState.requirePatternsUserInput,
          userInputReadBack: uiCheckboxesState.userInputReadBack, isRegexValidation: isChecked
        });
        break;
      case ATTRIBUTES.USER_INPUT_REQUIRE_PATTERNS:
        //if Pattern is not needed, clean Pattern fields as well
        if (!isChecked) {
          ElementService.updateElement(element, ATTRIBUTES.USER_INPUT_OPTION_INPUT_PATTERN, "");
        }
        setCheckboxesPropsInState({
          requirePrefixSymbolUserInput: uiCheckboxesState.requirePrefixSymbolUserInput,
          requireSeparatorUserInput: uiCheckboxesState.requireSeparatorUserInput, requirePatternsUserInput: isChecked,
          userInputReadBack: uiCheckboxesState.userInputReadBack, isRegexValidation: uiCheckboxesState.isRegexValidation
        });
        break;
      case ATTRIBUTES.USER_INPUT_REQUIRE_VOICE_FILE:
        //if Voice File is not needed, clean userInputVoiceFile field as well
        if (!isChecked) {
          ElementService.updateElement(element, ATTRIBUTES.USER_INPUT_OPTION_INPUT_SYMBOL_VOICE_FILE, "");
        }
        break;
      case ATTRIBUTES.USER_INPUT_REQUIRE_SEPARATOR:
        //if Separator is not needed, clean Separator fields as well
        if (!isChecked) {
          ElementService.updateElement(element, ATTRIBUTES.USER_INPUT_OPTION_INPUT_SEPARATOR, "");
        }
        setCheckboxesPropsInState({
          requirePrefixSymbolUserInput: uiCheckboxesState.requirePrefixSymbolUserInput,
          requireSeparatorUserInput: isChecked, requirePatternsUserInput: uiCheckboxesState.requirePatternsUserInput,
          userInputReadBack: uiCheckboxesState.userInputReadBack,
          isRegexValidation: uiCheckboxesState.isRegexValidation
        });
        break;
      case ATTRIBUTES.USER_INPUT_REQUIRE_PREFIX:
        //if Prefix Symbol is not needed, clean userInputPrefixSymbol field as well
        if (!isChecked) {
          ElementService.updateElement(element, ATTRIBUTES.USER_INPUT_OPTION_INPUT_PREFIX_SYMBOL, "");
        }
        setCheckboxesPropsInState({
          requirePrefixSymbolUserInput: isChecked,
          requireSeparatorUserInput: uiCheckboxesState.requireSeparatorUserInput,
          requirePatternsUserInput: uiCheckboxesState.requirePatternsUserInput,
          userInputReadBack: uiCheckboxesState.userInputReadBack,
          isRegexValidation: uiCheckboxesState.isRegexValidation
        });
        break;
      case ATTRIBUTES.USER_INPUT_OPTION_READBACK:
        //if readback is not needed, clean digit count as well
        if (!isChecked) {
          ElementService.updateElement(element, ATTRIBUTES.USER_INPUT_OPTION_PROMT_LAST_DIGITS_COUNT, 0);
          ElementService.updateElement(element, ATTRIBUTES.USER_INPUT_READBACK_INPUT_DATA_TYPE, "alpha");
        }
        setCheckboxesPropsInState({
          requirePrefixSymbolUserInput: uiCheckboxesState.requirePrefixSymbolUserInput,
          requireSeparatorUserInput: uiCheckboxesState.requireSeparatorUserInput,
          requirePatternsUserInput: uiCheckboxesState.requirePatternsUserInput,
          userInputReadBack: isChecked, isRegexValidation: uiCheckboxesState.isRegexValidation
        });
        break;
      default:
        break;
    }
  }

  /**
   * Handles the validation of user input
   * @param {string} input value
   */
  const handleInputValidation = (input) => {
    setUiState({ ...uiState, validationInput: input });
    if (!input) {
      setUiState({ ...uiState, errMsg: "", validationInput: input });
      return;
    }
    // check if regex is valid
    if (AppUtil.isValidRegex(input)) {
      setUiState({ ...uiState, errMsg: "", validationInput: input });
      ElementService.updateElement(element, ATTRIBUTES.USER_INPUT_REGEX_VALIDATION, input);
    } else {
      setUiState({
        ...uiState, errMsg: MESSAGES.ERR.INPUT_RANGE_VALIDATION,
        validationInput: input
      });
    }
  }

  return (
    <>
      <VoiceContext.Provider
        value={{
          supportedLanguages: supportedLanguages, selectedLanguage: selectedLanguage, uploadRef: uploadRef,
          updateVoiceFileStateInfo: updateVoiceFileStateInfo, userInputInvalidInputVoiceFileUploadType: userInputInvalidInputVoiceFileUploadType,
          handleVoiceFileUploadMethod: handleVoiceFileUploadMethod,
          invalidVoiceFilePath: invalidVoiceFilePath, invalidVoiceFileInfo: invalidVoiceFileInfo,
          updateVoiceFilePathState: updateVoiceFilePathState, ttsRef: ttsRef, element: element,
          rightPanelEventHandler: rightPanelEventHandler, resetStates: resetStates
        }}>
        {
          (tabType === TAB_LIST)
            ? <>
              <button className={`nav-link ${uiState.activeTab === 'custom-audio' ? 'active' : ''}`}
                id="custom-audio" data-bs-toggle="tab"
                data-bs-target="#edit-custom-audio" type="button" role="tab" aria-controls="nav-profile"
                aria-selected={uiState.activeTab === 'custom-audio'}
                onClick={(event) => setUiState({ ...uiState, activeTab: event.currentTarget?.id })}
                ref={createAudioTabRef}>Create audio</button>
              <button className={`nav-link ${uiState.activeTab === 'user-input' ? 'active' : ''}`} id="user-input" data-bs-toggle="tab" data-bs-target="#edit-user-input"
                type="button" role="tab" aria-controls="edit-user-input" aria-selected={uiState.activeTab === 'user-input'}
                onClick={(event) => setUiState({ ...uiState, activeTab: event.currentTarget?.id })}>Input</button>
            </>
            :
            <>
              <div className={`tab-pane fade ${uiState.activeTab === 'custom-audio' ? 'active show' : ''} mt-1`}
                id="edit-custom-audio" role="tabpanel" aria-labelledby="nav-home-tab">
                <VoicePropsView element={element} rightPanelEventHandler={rightPanelEventHandler} />
              </div>
              <div className={`tab-pane fade ${uiState.activeTab === 'user-input' ? 'active show' : ''}`}
                id="edit-user-input" role="tabpanel" aria-labelledby="nav-home-tab">
                <div className=" m-2 mt-3">
                  <PmivrCheckbox label={"End Call If Invalid User Input After Retries"}
                    value={element.businessObject.get(ATTRIBUTES.END_CALL_ON_INVALID_USER_INPUT_AFTER_RETRIES) || false}
                    info={TOOLTIP.INFO.END_CALL_IF_INVALID_INPUT_AFTER_RETRY}
                    onChange={(value) => {
                      ElementService.updateElement(element, ATTRIBUTES.END_CALL_ON_INVALID_USER_INPUT_AFTER_RETRIES, value);
                    }} />
                  <PmivrCheckbox label={"Allow Input During Readback"}
                    value={element.businessObject.get(ATTRIBUTES.ALLOW_INPUT_DURING_READBACK) || false}
                    info={TOOLTIP.INFO.ALLOW_INPUT_DURING_READBACK}
                    onChange={(value) => {
                      ElementService.updateElement(element, ATTRIBUTES.ALLOW_INPUT_DURING_READBACK, value);
                    }} />
                  <PmivrCheckbox label={"Submit Input With # Key"}
                    value={element.businessObject.get(ATTRIBUTES.SUBMIT_INPUT_WITH_HASH_KEY_ENABLED) || false}
                    info={TOOLTIP.INFO.SUBMIT_INPUT_WITH_HASH_KEY_ENABLED}
                    disabled={element.businessObject.get(ATTRIBUTES.USER_INPUT_ALLOW_EMPTY_VALUES_AS_INPUT) || false}
                    onChange={(value) => {
                      ElementService.updateElement(element, ATTRIBUTES.SUBMIT_INPUT_WITH_HASH_KEY_ENABLED, value);
                    }} />
                  <PmivrCheckbox label={"Allow Empty Values As Input With # Key"}
                    value={element.businessObject.get(ATTRIBUTES.USER_INPUT_ALLOW_EMPTY_VALUES_AS_INPUT) || false}
                    info={TOOLTIP.INFO.USER_INPUT_ALLOW_EMPTY_VALUES_AS_INPUT}
                    onChange={(value) => {
                      ElementService.updateElement(element, ATTRIBUTES.USER_INPUT_ALLOW_EMPTY_VALUES_AS_INPUT, value);
                      ElementService.updateElement(element, ATTRIBUTES.SUBMIT_INPUT_WITH_HASH_KEY_ENABLED, value);
                    }} />
                  <div className="form-group mb-3">
                    <PmivrLabel label="Input Variable" tooltip={TOOLTIP.INFO.USER_INPUT_VARIABLE} />
                    <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.USER_INPUT_VARIABLE}>
                      <input
                        id="inputVar"
                        name="inputVar"
                        className="form-control pmivr-input"
                        placeholder="Enter Variable"
                        value={ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_OPTION_INPUT_VAR) || ""}
                        onChange={(event) => {
                          ElementService.updateElement(element, ATTRIBUTES.USER_INPUT_OPTION_INPUT_VAR, event.target.value);
                        }}
                      />
                    </PmivrOverlayTrigger>
                  </div>
                  <div className="form-group mb-3">
                    <PmivrLabel label="Input Retry Count" tooltip={TOOLTIP.INFO.USER_INPUT_RETRY_COUNT} />
                    <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.USER_INPUT_RETRY_COUNT}>
                      <input
                        id="inputVar" name="inputVar" type="number" className="form-control pmivr-input" placeholder="Enter Retry Count"
                        defaultValue={DEFAULT_RETRY_COUNT}
                        value={ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_OPTION_RETRY_COUNT)}
                        onChange={(event) => {
                          ElementService.updateElement(element, ATTRIBUTES.USER_INPUT_OPTION_RETRY_COUNT, event.target.value);
                        }}
                      />
                    </PmivrOverlayTrigger>
                  </div>
                  <div className="form-group mb-3">
                    <PmivrLabel label="Input Timeout" tooltip={TOOLTIP.INFO.USER_INPUT_TIMEOUT} />
                    <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.USER_INPUT_TIMEOUT}>
                      <input
                        id="inputVar" name="inputVar" type="number" className="form-control pmivr-input"
                        placeholder="Enter Timeout"
                        value={ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_OPTION_TIMEOUT) || ""}
                        onChange={(event) => {
                          ElementService.updateElement(element, ATTRIBUTES.USER_INPUT_OPTION_TIMEOUT, event.target.value);
                        }}
                      />
                    </PmivrOverlayTrigger>
                  </div>
                  <div className="form-group mb-3">
                    <PmivrLabel label="Decimal Precision" tooltip={TOOLTIP.INFO.USER_INPUT_PRECISION} />
                    <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.USER_INPUT_PRECISION}>
                      <input
                        id="precision" name="precision" type="number" className="form-control pmivr-input" placeholder="Enter Value"
                        value={ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_PRECISION) || ""}
                        onChange={(event) => {
                          ElementService.updateElement(element, ATTRIBUTES.USER_INPUT_PRECISION, event.target.value);
                        }}
                      />
                    </PmivrOverlayTrigger>
                  </div>
                  <hr />
                  <PmivrLabel label="Invalid Voice File" tooltip={TOOLTIP.INFO.INVALID_VOICE_FILE} />
                  {supportedLanguages.map((lang) => {
                    return (
                      <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.INVALID_VOICE_FILE}>
                        <div className="form-group custom-input invalid-voice-file-info">
                          <input
                            type="text"
                            className="pmivr-input lang-input"
                            value={lang}
                            disabled={true}
                            size="10"
                            placeholder={"Text"}
                          />
                          <input
                            type="text"
                            className="pmivr-input path-input"
                            value={getInvaildVoiceFileInfo(lang)}
                            disabled={true}
                            size="40"
                            placeholder={"Text"}
                          />
                        </div>
                      </PmivrOverlayTrigger>
                    )
                  })}
                  <div className="text-center mt-2">
                    <button className=" mt-2 mb-3 p-2 text-center pmivr-btn-app" onClick={(event) => { handleShowOptionMenu(); }}>
                      <i className="bi bi-pencil"> </i> Invalid File Option
                    </button>
                  </div>
                  <hr />
                  <PmivrDialog showDialog={showOptionMenu} closeDialog={handleCloseOptionMenu}
                    title={`Invalid File Option`} message={<UserInputProperties supportedLanguages={supportedLanguages} selectedLanguage={selectedLanguage} uploadRef={uploadRef}
                      updateVoiceFileStateInfo={updateVoiceFileStateInfo} userInputInvalidInputVoiceFileUploadType={userInputInvalidInputVoiceFileUploadType}
                      handleVoiceFileUploadMethod={handleVoiceFileUploadMethod}
                      invalidVoiceFilePath={invalidVoiceFilePath} invalidVoiceFileInfo={invalidVoiceFileInfo}
                      updateVoiceFilePathState={updateVoiceFilePathState} ttsRef={ttsRef} element={element}
                      rightPanelEventHandler={rightPanelEventHandler} resetStates={resetStates} />} saveDialogChanges={handleSaveOptionMenu} />
                  <SpeechInput />
                  <hr />
                  <div className="row">
                    <div className="col">
                      <div className="form-check pmivr-check-radio form-check-inline mt-3 mb-2 check-box-label-align">
                        <input
                          id="userInputReadBack"
                          name="userInputReadBack"
                          className="form-check-input  radio"
                          type="checkBox"
                          checked={uiCheckboxesState.userInputReadBack || false}
                          onChange={(e) => updateUserInputAttribute(element, e, ATTRIBUTES.USER_INPUT_OPTION_READBACK)}
                        />
                        <label className="form-check-label ">Readback of Input Data</label>
                        <PmivrTooltip message={TOOLTIP.INFO.IS_READBACK_INPUT}>
                          <i className={`${TASK_ICONS.DISPLAY_INFO} check-box-info-icon`}></i>
                        </PmivrTooltip>
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.INPUT_DATA_READBACK}>
                      <input
                        id="userInputPromptLastDigitsCount"
                        name="userInputPromptLastDigitsCount"
                        className="form-control pmivr-input"
                        type="number"
                        value={ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_OPTION_PROMT_LAST_DIGITS_COUNT, "")}
                        onChange={(event) => {
                          ElementService.updateElement(element, ATTRIBUTES.USER_INPUT_OPTION_PROMT_LAST_DIGITS_COUNT, event.target.value);
                        }}
                        disabled={!uiCheckboxesState.userInputReadBack}
                        placeholder="Enter Digit Count To Prompt (from Last)"
                      />
                    </PmivrOverlayTrigger>
                  </div>
                  <ReadbackInputData element={element} disabled={!uiCheckboxesState.userInputReadBack}
                    rightPanelEventHandler={rightPanelEventHandler} />
                  <hr />

                  <Accordion activeKey={activeAccordionKey} className="mt-3 pmivr-accordion"
                    onSelect={(selectedKey) => setActiveAccordionKey(selectedKey)} flush>
                    <Accordion.Item eventKey={"validation-user-input"} className="mt-3 accordion-voice-item">
                      <Accordion.Header>
                        <span className="pmivr-accordian-tab">
                          Validations
                        </span>
                      </Accordion.Header>
                      <Accordion.Body className="p-3 pt-0">
                        <div className="row">
                          <div className="col">
                            <div className="form-check pmivr-check-radio form-check-inline mt-3 mb-2 check-box-label-align">
                              <input
                                id="isRegexValidation"
                                name="isRegexValidation"
                                className="form-check-input  radio"
                                type="checkBox"
                                checked={uiCheckboxesState.isRegexValidation}
                                onChange={(e) => updateUserInputAttribute(element, e, ATTRIBUTES.USER_INPUT_IS_REGEX_VALIDATION)}
                              />
                              <label className="form-check-label ">Enable Validation Regex</label>
                              <PmivrTooltip message={TOOLTIP.INFO.IS_REGEX_VALIDATION}>
                                <i className={`${TASK_ICONS.DISPLAY_INFO} check-box-info-icon`}></i>
                              </PmivrTooltip>
                            </div>
                          </div>
                        </div>
                        <div className="form-group mb-3">
                          <PmivrLabel label="Validation Regex" tooltip={TOOLTIP.INFO.USER_INPUT_REGEX_VALIDATION}
                            disabled={!uiCheckboxesState.isRegexValidation} />
                          <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.USER_INPUT_REGEX_VALIDATION}>
                            <input id="regexValidation" name="regexValidation" className="form-control pmivr-input" type="text"
                              placeholder="Input validation regex" // this is required as it will use mentioned label as its value
                              value={uiState?.validationInput}
                              onChange={(event) => { handleInputValidation(event.target.value) }}
                              disabled={!uiCheckboxesState.isRegexValidation}
                            />
                          </PmivrOverlayTrigger>
                          {uiState?.errMsg && <div className="field-error">{uiState?.errMsg}</div>}
                        </div>
                        <div className="row">
                          <div className="col-md-6 form-group mb-3">
                            <PmivrLabel label="Min Digits" tooltip={TOOLTIP.INFO.USER_INPUT_MIN_DIGITS} />
                            <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.USER_INPUT_MIN_DIGITS}>
                              <input id="minDigits" name="minDigits" className="form-control pmivr-input" type="number"
                                placeholder="Enter Min Digits"
                                value={ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_MIN_DIGITS) || ""}
                                onChange={(event) => {
                                  ElementService.updateElement(element, ATTRIBUTES.USER_INPUT_MIN_DIGITS, event.target.value);
                                }}
                                disabled={uiCheckboxesState.isRegexValidation}
                              />
                            </PmivrOverlayTrigger>
                          </div>
                          <div className="col-md-6 form-group mb-3">
                            <PmivrLabel label="Max Digits" tooltip={TOOLTIP.INFO.USER_INPUT_MAX_DIGITS} />
                            <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.USER_INPUT_MAX_DIGITS}>
                              <input
                                id="maxDigits" name="maxDigits" className="form-control pmivr-input" type="number" placeholder="Enter Max Digits"
                                value={ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_MAX_DIGITS) || ""}
                                onChange={(event) => {
                                  ElementService.updateElement(element, ATTRIBUTES.USER_INPUT_MAX_DIGITS, event.target.value);
                                }}
                                disabled={uiCheckboxesState.isRegexValidation}
                              />
                            </PmivrOverlayTrigger>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12 form-group mb-3">
                            <PmivrLabel label="Min Value Type" tooltip={TOOLTIP.INFO.USER_INPUT_MIN_VALUE_TYPE} />
                            <select id="minValueInputType" name="minValueInputType" className="pmivr-select"
                              disabled={uiCheckboxesState.isRegexValidation}
                              value={ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_MIN_VALUE_TYPE) || ""}
                              onChange={(event) => {
                                ElementService.updateElement(element, ATTRIBUTES.USER_INPUT_MIN_VALUE_TYPE, event.target.value);
                                // reset value of variable / value for minimum value of user input
                                updateVariableInfo('', 'minValue');
                              }}>
                              <option key={'Select Type'} value={''}>Select</option>
                              {
                                VALUE_TYPE_OPTIONS.map((type) => {
                                  return (
                                    <option key={type.value} value={type.value}>
                                      {type.text}
                                    </option>
                                  );
                                })
                              }
                            </select>
                          </div>
                          <div className="col-md-12 form-group mb-3">
                            <PmivrLabel label="Min Value" tooltip={TOOLTIP.INFO.USER_INPUT_MIN_VALUE} />
                            {ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_MIN_VALUE_TYPE) === "variable" ?
                              <PmivrOverlayTrigger tooltip={variableInfo.minValueSelectedVariableDesc}>
                                <Typeahead id="basic-typeahead-single" className='pmivr-variable-input' labelKey="selectedMinValueVariable"
                                  onChange={(selected) => handleVariableSelection(selected, 'minValue')}
                                  placeholder="Select Variable" multiple={false} options={variableInfo.variablesName}
                                  onInputChange={(text, event) => updateVariableInfo(text, 'minValue')}
                                  selected={variableInfo.minValueSelectedVariable ? [variableInfo.minValueSelectedVariable] : []} />
                              </PmivrOverlayTrigger>
                              : <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.USER_INPUT_MIN_VALUE}>
                                <input id="minValue" name="minValue" className="form-control pmivr-input" placeholder="Enter Min Value" type="number"
                                  value={ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_MIN_VALUE) || ""}
                                  onChange={(event) => {
                                    ElementService.updateElement(element, ATTRIBUTES.USER_INPUT_MIN_VALUE, event.target.value);
                                  }}
                                  disabled={uiCheckboxesState.isRegexValidation
                                    || !AppUtil.isValueValid(ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_MIN_VALUE_TYPE))}
                                />
                              </PmivrOverlayTrigger>}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12 form-group mb-3">
                            <PmivrLabel label="Max Value Type" tooltip={TOOLTIP.INFO.USER_INPUT_MAX_VALUE_TYPE} />
                            <select id="maxValueInputType" name="maxValueInputType" className="pmivr-select"
                              disabled={uiCheckboxesState.isRegexValidation}
                              value={ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_MAX_VALUE_TYPE) || ""}
                              onChange={(event) => {
                                ElementService.updateElement(element, ATTRIBUTES.USER_INPUT_MAX_VALUE_TYPE, event.target.value);
                                // reset value of variable / value for minimum value of user input
                                updateVariableInfo('');
                              }}>
                              <option key={'Select Type'} value={''}>
                                Select
                              </option>
                              {
                                VALUE_TYPE_OPTIONS.map((type) => {
                                  return (
                                    <option key={type.value} value={type.value}>
                                      {type.text}
                                    </option>
                                  );
                                })
                              }
                            </select>
                          </div>
                          <div className="col-md-12 form-group mb-3">
                            <PmivrLabel label="Max Value" tooltip={TOOLTIP.INFO.USER_INPUT_MAX_VALUE} />
                            {ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_MAX_VALUE_TYPE) === "variable" ?
                              <PmivrOverlayTrigger tooltip={variableInfo.maxValueSelectedVariableDesc}>
                                <Typeahead id="basic-typeahead-single" className='pmivr-variable-input' labelKey="selectedMaxValueVariable"
                                  onChange={(selected) => handleVariableSelection(selected)} placeholder="Select Variable" multiple={false}
                                  // only show the simple variables in option
                                  options={variableInfo.variablesName} onInputChange={(text, event) => updateVariableInfo(text)}
                                  selected={variableInfo.maxValueSelectedVariable ? [variableInfo.maxValueSelectedVariable] : []} />
                              </PmivrOverlayTrigger>
                              : <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.USER_INPUT_MAX_VALUE}>
                                <input id="maxValue" name="maxValue" className="form-control pmivr-input" placeholder="Enter Max Value" type="number"
                                  value={ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_MAX_VALUE) || ""}
                                  onChange={(event) => {
                                    ElementService.updateElement(element, ATTRIBUTES.USER_INPUT_MAX_VALUE, event.target.value);
                                  }}
                                  disabled={uiCheckboxesState.isRegexValidation
                                    || !AppUtil.isValueValid(ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_MAX_VALUE_TYPE))
                                  }
                                />
                              </PmivrOverlayTrigger>
                            }
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <hr />
                  <div className="row">
                    <div className="col">
                      <div className="form-check pmivr-check-radio form-check-inline mt-3 mb-2 check-box-label-align">
                        <input
                          id="requirePatternsUserInput"
                          name="requirePatternsUserInput"
                          className="form-check-input radio"
                          type="checkBox"
                          checked={uiCheckboxesState.requirePatternsUserInput}
                          onChange={(e) => updateUserInputAttribute(element, e, ATTRIBUTES.USER_INPUT_REQUIRE_PATTERNS)}
                        />
                        <label className="form-check-label ">AlphaNumeric Pattern?</label>
                        <PmivrTooltip message={TOOLTIP.INFO.IS_ALPHA_NUMERIC_PATTERN}>
                          <i className={`${TASK_ICONS.DISPLAY_INFO} check-box-info-icon`}></i>
                        </PmivrTooltip>
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.ALPHANUMERIC_PATTERN}>
                      <input
                        id="userInputPattern"
                        name="userInputPattern"
                        className="form-control pmivr-input"
                        value={ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_OPTION_INPUT_PATTERN, "")}
                        onChange={(event) => {
                          ElementService.updateElement(element, ATTRIBUTES.USER_INPUT_OPTION_INPUT_PATTERN, event.target.value);
                        }}
                        disabled={!uiCheckboxesState.requirePatternsUserInput}
                        placeholder="Enter Alphanumeric Pattern"
                      />
                    </PmivrOverlayTrigger>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="form-check pmivr-check-radio form-check-inline mt-3 mb-2 check-box-label-align">
                        <input
                          id="requireSeparatorUserInput"
                          name="requireSeparatorUserInput"
                          className="form-check-input  radio"
                          type="checkBox"
                          checked={uiCheckboxesState.requireSeparatorUserInput}
                          onChange={(e) => updateUserInputAttribute(element, e, ATTRIBUTES.USER_INPUT_REQUIRE_SEPARATOR)}
                        />
                        <label className="form-check-label ">Input Separator?</label>
                        <PmivrTooltip message={TOOLTIP.INFO.IS_INPUT_SEPERATOR}>
                          <i className={`${TASK_ICONS.DISPLAY_INFO} check-box-info-icon`}></i>
                        </PmivrTooltip>
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.INPUT_SEPARATOR}>
                      <input
                        id="userInputPatternSeparator"
                        name="userInputPatternSeparator"
                        className="form-control pmivr-input"
                        value={ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_OPTION_INPUT_SEPARATOR, "")}
                        onChange={(event) => {
                          ElementService.updateElement(element, ATTRIBUTES.USER_INPUT_OPTION_INPUT_SEPARATOR, event.target.value);
                        }}
                        disabled={!uiCheckboxesState.requireSeparatorUserInput}
                        placeholder="Enter Separator"
                      />
                    </PmivrOverlayTrigger>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="form-check pmivr-check-radio form-check-inline mt-3 mb-2 check-box-label-align">
                        <input
                          id="requirePrefixSymbolUserInput"
                          name="requirePrefixSymbolUserInput"
                          className="form-check-input radio"
                          type="checkBox"
                          checked={uiCheckboxesState?.requirePrefixSymbolUserInput}
                          onChange={(e) => updateUserInputAttribute(element, e, ATTRIBUTES.USER_INPUT_REQUIRE_PREFIX)}
                        />
                        <label className="form-check-label">Input Prefix?</label>
                        <PmivrTooltip message={TOOLTIP.INFO.IS_INPUT_PREFIX}>
                          <i className={`${TASK_ICONS.DISPLAY_INFO} check-box-info-icon`}></i>
                        </PmivrTooltip>
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.INPUT_PREFIX}>
                      <input
                        id="userInputPrefixSymbol"
                        name="userInputPrefixSymbol"
                        className="form-control pmivr-input"
                        value={ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_OPTION_INPUT_PREFIX_SYMBOL, "")}
                        onChange={(event) => {
                          ElementService.updateElement(element, ATTRIBUTES.USER_INPUT_OPTION_INPUT_PREFIX_SYMBOL, event.target.value);
                        }}
                        disabled={!uiCheckboxesState?.requirePrefixSymbolUserInput}
                        placeholder="Enter Prefix"
                      />
                    </PmivrOverlayTrigger>
                  </div>
                </div>
              </div>
            </>
        }
      </VoiceContext.Provider>
    </>
  )
}

export default UserInputPropertiesView;