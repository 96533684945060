import { MODELER_EVENTS } from "../constants/events";
import { EL_TYPE } from "../constants/element";

import DiagramUtil from "../util/diagram.util";

import DiagramService from "./diagram.service";

/**
 * Handles actions on different bpmn events which will be triggered by user actions
 * such as highlighting component, updating change history etc.
 */
class BpmnEventHandlerService {
    constructor(modeler, historyDialogProps, updateChanges) {
        // bpmn modeler
        this.modeler = modeler;
        // history dialog object to store the change history
        this.historyDialogProps = historyDialogProps;
        // function to update changes in the change history props in parent component
        this.updateChanges = updateChanges;
        // array to keep the id's of element withing subprocess when a subprocess is deleted
        this.subprocessChildren = [];
        this._initializeListeners();
    }


    // initialize all listener instances
    _initializeListeners() {
        this.modeler.on(MODELER_EVENTS.CONTEXT_PAD_OPEN, this._onContextPadOpen.bind(this));
        this.modeler.on(MODELER_EVENTS.ELEMENT_MOVED, this._onElementMoved.bind(this));
        this.modeler.on(MODELER_EVENTS.UPDATE_PROPERTY, this._onUpdateProperty.bind(this));
        this.modeler.on(MODELER_EVENTS.UPDATE_NAME, this._onUpdateName.bind(this));
        this.modeler.on(MODELER_EVENTS.CONNECTION_CREATED, this._onConnectionCreated.bind(this));
        this.modeler.on(MODELER_EVENTS.CREATE_SHAPE, this._onCreateShape.bind(this));
        this.modeler.on(MODELER_EVENTS.UNDO_CONNECTION_DELETE, this._onUndoConnectionDelete.bind(this));
        this.modeler.on(MODELER_EVENTS.ELEMENT_DELETE, this._onElementDelete.bind(this));
        this.modeler.on(MODELER_EVENTS.UNDO_ELEMENT_DELETE, this._onUndoElementDelete.bind(this));
    }

    /**
     * Listens when custom context pad is opened
     * @param {Object} event 
     */
    _onContextPadOpen(event) {
        // If by direct connection line menu option, any element is connected from condition control then it 
        // is creating confusion as this will not be in condition info
        // also condition control automatically creates connection lines based on mentioned condition 
        // so it is creating confusion
        if (event.current.element.type === EL_TYPE.GATEWAY) {
            const conditionElement = document.querySelector('.bpmn-icon-connection-multi');
            if (conditionElement) {
                conditionElement.classList.add('pmivr-disabled');
            }
        }
        // disable deleting the connection lines which are condition based as this distrurbes the conditions 
        if (event.current.element.type === EL_TYPE.SEQUENCE_FLOW) {
            const sequenceFlowDelete = document.querySelector('.bpmn-icon-trash');
            const isConditionGateway = event.current.element.source.type === EL_TYPE.GATEWAY;
            if (isConditionGateway) {
                sequenceFlowDelete.classList.add('pmivr-disabled');
            }
        }
    }

    /**
     * listen the element move event to highlight the element that is in change history
     * @param {Object} event 
     */
    _onElementMoved(event) {
        DiagramUtil.processElementMovedEvent(event, this.historyDialogProps.changeHistory);
    }

    /**
     * element property was updated
     * @param {Object} event 
     */
    _onUpdateProperty(event) {
        const response = DiagramUtil.processUpdatePropertyEvent(event, this.historyDialogProps.changeHistory);
        if (response.isUpdated) {
            this.updateChanges(event.context.element, response.changeHistory);
        }
    }

    /**
     * Listen to name updated for any element
     * @param {Object} event 
     * @returns {void} 
     */
    _onUpdateName(event) {
        const element = event.context.element;
        // this is to check if the update name is triggered for elements within a subprocess when the subprocess was deleted 
        if (this.subprocessChildren.includes(element.id)) {
            // do not update change history if subprocess children are updated to null 
            return;
        } else {
            const response = DiagramUtil.processUpdateElementNameEvent(event, this.historyDialogProps.changeHistory);
            if (response.isUpdated) {
                this.updateChanges(event.context.element, response.changeHistory);
            }
        }
    }

    /**
     * Listen to creating a connection
     * @param {Object} event 
     */
    _onConnectionCreated(event) {
        const response = DiagramUtil.processConnectionCreate(event, this.historyDialogProps.changeHistory);
        if (response.isUpdated) {
            this.updateChanges(event.context.connection, response.changeHistory);
        }
    }

    /**
     * New element is created on the diagram canvas
     * @param {Object} event 
     */
    _onCreateShape(event) {
        // add default name to the event
        DiagramUtil.addDefaultNameToEvent(event);
        const response = DiagramUtil.processCreatedElementEvent(event, this.historyDialogProps.changeHistory);
        if (response.isUpdated) {
            this.updateChanges(event.context.shape, response.changeHistory);
        }
    }

    /**
     * Listens undo a delete of connection line (pressing ctrl + z after deleting a connection)
     * @param {Object} event 
     */
    _onUndoConnectionDelete(event) {
        const response = DiagramUtil.processUndoConnectionDelete(event, this.historyDialogProps.changeHistory);
        if (response.isUpdated) {
            this.updateChanges(null, response.changeHistory);
        }
    }

    /**
     * Listen node delete event
     * @param {Object} event 
     */
    _onElementDelete(event) {
        const element = event.context.elements[0];
        if (element.type === EL_TYPE.SUB_PROCESS) {
            // Add the subprocess and its children to the subprocess children array
            DiagramService.collectSubprocessElements(element, this.subprocessChildren);
        }
        // update the condition if it is attached to deleted node
        DiagramUtil.updateConditionElement(event);
        // update the change history
        const response = DiagramUtil.processElementDelete(event, this.historyDialogProps.changeHistory);
        if (response.isUpdated) {
            this.updateChanges(null, response.changeHistory);
        }
    }

    /**
     * Listens undo a delete of connection line (pressing ctrl + z after deleting a connection) 
     * @param {Object} event 
     */
    _onUndoElementDelete(event) {
        const element = event.context.shape;
        // on undo, collapse icon should re-appear on the subprocess rectangle
        if (element.type === EL_TYPE.SUB_PROCESS) {
            DiagramService.addExpandCollapseArrows(element);
        }
        // because the id's of deleted element will be present in the array, and after undo we need to clear the array
        this.subprocessChildren = [];
        const response = DiagramUtil.processUndoElementDelete(event, this.historyDialogProps.changeHistory);
        if (response.isUpdated) {
            this.updateChanges(null, response.changeHistory);
        }
    }
}

export default BpmnEventHandlerService;